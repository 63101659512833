import { MdFormatAlignLeft, MdReport  } from 'react-icons/md'
import { RiPencilRuler2Fill } from 'react-icons/ri'
import { FaUserSecret } from 'react-icons/fa'
import { GiPartyPopper } from 'react-icons/gi'

export const DefaultJSONData = {
  "locale": "de",
  "language_options": [
    {
      "value": "en",
      "label": "English"
    },
    {
      "value": "de",
      "label": "Deutsch"
    }
  ],
  "form_content": {
    "backgroundStyle": {
      "background": "#fff"
    },
    "title": {
      "key": "whistle_blower_msg",
      "label": {
        "de": "<h2>Hinweisgeber Meldung</h2>",
        "en": "<h2>Whistleblower Message</h2>"
      }
    },
    "content": [
      {
        "type": "field",
        "variant": "textarea",
        "visible": true,
        "required": true,
        "key": "description",
        "placeholder": {
          "en": "",
          "de": ""
        },
        "label": {
          "de": "<span>Worauf bezieht sich Ihr Verdacht?</span>",
          "en": "<span>What is your suspicion?</span>"
        }
      },
      {
        "type": "field",
        "variant": "file",
        "visible": true,
        "required": false,
        "key": "bkp-case-attachment",
        "placeholder": {
          "en": "",
          "de": ""
        },
        "label": {
          "de": "<span>Anhang</span>",
          "en": "<span>Case Attachment</span>"
        },
        "btn_label": {
          "de": "<span>Datei und Anhang hochladen</span>",
          "en": "<span>Upload file and attachment</span>"
        }
      },
      {
        "type": "field",
        "variant": "text",
        "visible": true,
        "required": false,
        "key": "company_department",
        "placeholder": {
          "en": "",
          "de": ""
        },
        "label": {
          "en": "<span>Please give the name of the affected department</span>",
          "de": "<span>Bitte geben Sie den Namen der betroffenen Abteilung an</span>"
        }
      },
      {
        "type": "field",
        "variant": "text",
        "visible": true,
        "required": false,
        "key": "country",
        "placeholder": {
          "en": "",
          "de": ""
        },
        "label": {
          "en": "<span>In which country did the incident take place ?</span>",
          "de": "<span>In welchem Land hat sich der Vorfall ereignet ?</span>"
        }
      },
      {
        "type": "field",
        "variant": "text",
        "visible": true,
        "required": false,
        "key": "city",
        "placeholder": {
          "en": "",
          "de": ""
        },
        "label": {
          "en": "<span>In which city did the incident occur?</span>",
          "de": "<span>In welcher Stadt hat sich der Vorfall ereignet?</span>"
        }
      },
      {
        "type": "field",
        "variant": "checkbox",
        "visible": true,
        "required": false,
        "key": "is_anonymous",
        "placeholder": {
          "en": "",
          "de": ""
        },
        "label": {
          "en": "<span>Stay anonymous</span>",
          "de": "<span>Anonym bleiben</span>"
        }
      },
      {
        "type": "card",
        "key": "bkp-form-not-anonymous",
        "label": {
          "en": "<h5><strong>Contact information</h5></strong>",
          "de": "<h5><strong>Kontaktinformationen</h5></strong>"
        },
        "style": {
          "background": "#FFFFFF"
        },
        "content": [
          {
            "type": "field",
            "variant": "text",
            "visible": true,
            "required": false,
            "key": "email",
            "placeholder": {
              "en": "",
              "de": ""
            },
            "label": {
              "en": "<span>Email</span>",
              "de": "<span>E-Mail</span>"
            }
          },
          {
            "type": "field",
            "variant": "text",
            "visible": true,
            "required": false,
            "key": "name",
            "placeholder": {
              "en": "",
              "de": ""
            },
            "label": {
              "en": "<span>First and last name</span>",
              "de": "<span>Vor- und Nachname</span>"
            }
          },
          {
            "type": "field",
            "variant": "text",
            "visible": true,
            "required": false,
            "key": "contact",
            "placeholder": {
              "en": "",
              "de": ""
            },
            "label": {
              "en": "<span>Phone number</span>",
              "de": "<span>Telefonnummer</span>"
            }
          }
        ]
      },
      {
        "type": "card",
        "key": "bkp_pw_card",
        "label": {
          "en": "<h5><strong>Password</h5></strong>",
          "de": "<h5><strong>Passwort</h5></strong>"
        },
        "style": {
          "background": "#FFFFFF"
        },
        "content": [
          {
            "type": "field",
            "variant": "password",
            "visible": true,
            "required": true,
            "key": "password",
            "placeholder": {
              "en": "",
              "de": ""
            },
            "label": {
              "en": "<span>Password</span>",
              "de": "<span>Passwort</span>"
            }
          },
          {
            "type": "field",
            "variant": "password",
            "visible": true,
            "required": true,
            "key": "password_repeat",
            "placeholder": {
              "en": "",
              "de": ""
            },
            "label": {
              "en": "<span>Repeat Password</span>",
              "de": "<span>Passwort wiederholen</span>"
            }
          }
        ]
      }
    ],
    "submit_button": {
      "style": {
        "background": "#6390cf"
      },
      "label": {
        'en': '<span>Submit</span>',
        'de': '<span>Senden</span>'
      }
    }
  },
  "terms_content": {
    "backgroundStyle": {
      "background": "#edf1f4"
    },
    "title": {
      key: "safe_and_anonymous_title",
      label: {
        'en': '<h5><strong>Your data is safe & anonymous!</h5></strong>',
        'de': '<h5><strong>Ihre Daten sind sicher & anonym!</h5></strong>'
      }
    },
    "content": [
      {
        key: "hundred_anonymous_reporting",
        type: 'icon_list_item',
        label: {
          'en': '<span>100% anonymous reporting</span>',
          'de': '<span>100% anonyme Meldung</span>'
        }
      },
      {
        key: "certified_data_security",
        type: 'icon_list_item',
        label: {
          'en': '<span>certified data security</span>',
          'de': '<span>zertifizierte Datensicherheit</span>'
        }, 
      },
      {
        key: "independent_ombudsman",
        type: 'icon_list_item',
        label: {
          'en': '<span>independent ombudsman service</span>',
          'de': '<span>unabhängige Ombudsstelle</span>'
        }, 
      },
      {
        key: "what_is_reporting_procedure",
        type: 'anchor',
        label: {
          'en': '<p><strong>What is the reporting procedure?</strong></p>',
          'de': '<p><strong>Wie ist der Ablauf einer Meldung?</strong></p>'
        },
      },
      {
        key: "will_data_remain_anonymous",
        type: 'anchor',
        label: {
          "en": '<p><strong>Will my data remain anonymous?</strong></p>',
          "de": '<p><strong>Bleiben meine Daten anonym?</strong></p>'
        }
      }
    ],
    lawyer_contact_content: [
      {
        key: "give_hint_calling_following_number", 
        label: {
          "en": '<p><strong>You can also give your hint directly, by calling the following number:</strong></p>',
          "de": '<p><strong>Sie können auch direkt Ihren Hinweis, per Anruf unter folgender Nummer abgeben:</strong></p>'
        }, 
      },
      {
        key: "foc_from_all_german_network",
        label: {
          "en": '<small>Free of charge from all German landlines and all German mobile networks.</small>',
          "de": '<small>Kostenfrei von allen deutschen Festnetz und allen deutschen Mobilfunknetzen.</small>'
        }, 
      },
      {
        key: "working_hours",
        label: {
          "en": '<small>Mon to Thu: 08:00-17:00 - Fri 8:00 - 14:00</small>',
          "de": '<small>Mo bis Do: 08:00-17:00 - Fr 8:00 - 14:00</small>'
        }
      }
    ],
    "case_login_button": {
      "type": 'button',
      "style": {
        "background": "#6390cf"
      },
      "label": {
        'en': '<span>Case Login</span>',
        'de': '<span>Fall-Logins</span>'
      }
    }
  },
  "reporting_procedure_modal": {
    "backgroundStyle": {
      "background": "#edf1f4"
    },
    "title": {
      "key": "reporting_procedure_modal_title",
      "label": {
        'en': `<h5><strong>What is the reporting procedure?</h5></strong>`,
        'de': `<h5><strong>Wie ist der Ablauf einer Meldung?</h5></strong>`
      }, 
    },
    "content": [
      {
        key: "secure_mailbox_option_upon_submit_report",
        "label": {
          'de': `<ul><li>Wenn Sie eine Meldung übermitteln, haben Sie die Möglichkeit, ein sicheres Postfach zu erstellen und so für weitere Rückfragen zur Verfügung zu stehen.</li></ul>`,
          'en': `<ul><li>When you submit a report, you have the option of creating a secure mailbox so that you are available for further queries.</li></ul>`
        }
      },
      {
        key: "keep_yourself_available",
        "label": {
          'de': `<ul><li>Wir empfehlen, sich auf diese Weise verfügbar zu halten, da wir den Fall möglicherweise nicht ohne weitere Informationen von Ihnen abschließen können.</li></ul>`,
          'en': `<ul><li>We recommend keeping yourself available this way as we may not be able to close the case without further information from you.</li></ul>`
        }
  
      },
      {
        key: "case_num_password_assigned_check_quesion_in_email",
        "label": {
          'de': `<ul><li>Wenn Sie ein Postfach erstellen, wird Ihnen eine Fallnummer zugeteilt, und Sie müssen ein Passwort (PIN) wählen. Diese Fallnummer und dieses Passwort (PIN) müssen Sie verwenden, um Zugang zum Postfach zu erhalten und um nachzusehen, ob Sie Fragen erhalten haben.</li></ul>`,
          'en': `<ul><li>When you create a mailbox, you will be assigned a case number and you will need to choose a password (PIN). You will need to use this case number and password (PIN) to gain access to the mailbox and to see if you have received any questions..</li></ul>`
        }
      },
      {
        key: "mailbox_created_for_easier_communication",
        label: {
          'de': `<ul><li>Unabhängig davon, ob Sie anonym bleiben oder Ihren Namen angeben, bitten wir Sie, ein Postfach zu erstellen. Dies macht es einfacher und sicherer für uns, mit Ihnen zu kommunizieren.</li></ul>`,
          'en': `<ul><li>Regardless of whether you remain anonymous or give your name, we ask you to create a mailbox. This makes it easier and safer for us to communicate with you.</li></ul>`
        }
      },
      {
        key: "create_mail_easier_safer_communicate",
        label: {
          'de': `<ul><li>Unabhängig davon, ob Sie anonym bleiben oder Ihren Namen angeben, bitten wir Sie, ein Postfach zu erstellen. Dies macht es einfacher und sicherer für uns, mit Ihnen zu kommunizieren.</li></ul>`,
          'en': `<ul><li>Regardless of whether you remain anonymous or give your name, we ask you to create a mailbox. This makes it easier and safer for us to communicate with you.</li></ul>`
        }
      },
      {
        key: "communication_with_us_will_remain_anonymous_if_your_wish",
        label: {
          'de': `<ul><li>Wenn Sie es wünschen, bleibt sämtliche Kommunikation mit uns anonym.</li></ul>`,
          'en': `<ul><li>If you wish, all communication with us will remain anonymous.</li></ul>`
        }
      }
    ]
  },
  "data_remain_anonymous_modal": {
    "backgroundStyle": {
      "background": "#edf1f4"
    },
    "title": {
      "key": "data_remain_anonymous_modal_title",
      "label": {
        en: '<h5><strong>Will my data remain anonymous?<h5><strong>',
        de: '<h5><strong>Bleiben meine Daten anonym?<h5><strong>'
      }
    },
    "content": [
      {
        key: "to_ensure_anonymity",
        label: {
          "en": '<p>To ensure your anonymity, you need to do the following:</p>',
          "de": '<p>Um Ihre Anonymität sicherzustellen, müssen Sie folgendes tun:</p>'
        }
      },
      {
        key: "do_not_report_from_employer_pc",
        label: {
          "en": `<ul><li>If possible, do not run the report from your employer's computer.</li></ul>`,
          "de": `<ul><li>Erstellen Sie den Bericht, wenn möglich, nicht von einem Computer Ihres Arbeitgebers aus.</li></ul>`
        }
  
      },
      {
        key: "do_not_connect_to_company_network",
        label: {
          "en": '<ul><li>Do not use a PC that is connected to the company network / intranet of the company.</li></ul>',
          "de": `<ul><li>Verwenden Sie keinen PC, der ans Firmennetzwerk/Intranet der Firma angeschlossen ist.</li></ul>`
        }
  
      },
      {
        key: "enter_using_url_address_not_clicking_a_link",
        label: {
          "en": '<ul><li>Call up the reporting system by entering the URL address directly in the browser and not by clicking a link.</li></ul>',
          "de": `<ul><li>Rufen Sie das Meldesystem durch direktes Eingeben der URL-Adresse in den Browser und nicht über das Klicken eines Links auf.</li></ul>`
        }
      },
      {
        key: "do_not_write_personal_information_on_report",
        label: {
          "en": '<ul><li>Do not write personal information on the report</li></ul>',
          "de": `<ul><li>Schreiben Sie keine persönlichen Daten in den Bericht.</li></ul>`
        }
      }
    ]
  },
  "case_success_content": {
    "backgroundStyle": {
      "background": "#edf1f4"
    },
    "content": [
      {
        key: "report_successfully_submitted",
        label: {
          "en": '<p>Your report was successfully submitted and will be processed promptly. With your password and case number you can follow up the case at any time and send us additional messages. Please keep your case number and password in a safe place. If lost, access cannot be restored for anonymous users.</p>',
          "de": '<p>Ihre Meldung wurde erfolgreich übermittelt und wird zeitnah bearbeitet. Mit ihrem Passwort und ihrer Fallnummer können sie den Fall jederzeit weiter verfolgen und uns zusätzliche Nachrichten übermitteln. Bitte bewahren sie ihre Fallnummer und das Passwort gut auf. Bei Verlust kann bei anonymen Benützern der Zugang nicht wieder hergestellt werden.</p>'
        }
      }
    ],
    "download_case_summary_button": {
      "type": 'button',
      "style": {
        "background": "#6390cf"
      },
      "label": {
        'en': '<span>Download Case Summary</span>',
        'de': '<span>Fallinformationen herunterladen</span>'
      }
    }
  }
}

export const DefaultNewField = {
  "type": "field",
  "isAddtionalField": true,
  "variant": "text",
  "visible": true,
  "required": false,
  "key": "field_key",
  "placeholder": {
    "en": "",
    "de": ""
  },
  "label": {
    "en": "<span>Label</span>",
    "de": "<span>Etikett</span>"
  }
}

export const DefaultCard = {
	type: 'card',
	key: 'bkp-new-card',
  visible: true,
	label: {
		"en": '<h5><strong>New Card</h5></strong>',
		"de": '<h5><strong>New Card</h5></strong>'
	},
	style: {
		"background": "#FFFFFF"
	},
	content: []
}

export const FieldTypeOptions = [ 'text', 'textarea', 'file', 'checkbox', 'password' ]

export const TabEditOptions = [ 
  {
    value: 'form_content', 
    icon: <MdFormatAlignLeft/>,
  },
  {
    value: 'terms_content',
    icon: <RiPencilRuler2Fill/>
  },
  {
    value: 'reporting_procedure_modal', 
    icon: <MdReport/>
  },
  {
    value: 'data_remain_anonymous_modal', 
    icon: <FaUserSecret/>
  },
  {
    value: 'case_success_content', 
    icon: <GiPartyPopper/>
  }
]