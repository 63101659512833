import React, { createRef, useEffect } from 'react'
import { 
  Input, Form, Label, FormGroup, CustomInput, Button
} from 'reactstrap'
import _ from 'lodash'
import ReactHTMLParser from 'react-html-parser'

import CustomButton from './CustomButton'

const DragField = ({ 
  mode,
  editIndex,
  json, hasGroupedInCard,
  selectedEdit,
  onClickField,
  onChangeFieldForm
}) => {

  const isEditing = () => {
    if ( selectedEdit !== null && editIndex &&
      selectedEdit.fieldIndex === editIndex.fieldIndex && 
      editIndex.cardIndex === selectedEdit.cardIndex
    ){
      return true 
    } else {
      return false
    }
  }

  const fieldRef = createRef( null )
  useEffect(() => {
    if ( isEditing() ){
      fieldRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }, [ selectedEdit ])

  return (
    <div
      ref={ fieldRef }
      className={ `rounded transition_200  ${( selectedEdit === null && !hasGroupedInCard ) ? `bkp-form-renderer-form-hover` : '' }` }
      style={{ 
        cursor: 'pointer',
        position: 'relative',
        ... isEditing() && {
          boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
          border: '1px solid #467bc6',
          borderStyle: 'dotted'
        },
      }}
    >
      {
        json.variant !== 'file' && (
          <Form className={ `mb-3` }>
            <FormGroup>
              {
                json.variant === 'checkbox' && (
                  <CustomInput
                    key={ json.key }
                    id={ json.key }
                    disabled={ true }
                    type='checkbox'
                    className='mr-3'
                    label={ 
                      <>
                        <Label style={{ fontSize: '1rem' }}>
                          { ReactHTMLParser( json.label )}
                        </Label>
                        {
                          mode !== 'view' && (
                            <CustomButton
                              type={ 'edit' }
                              onClick={ onClickField }
                            />
                          )
                        }
                      </>
                    }
                    checked={ json.is_required }
                  />
                )
              }
              {
                json.variant !== 'checkbox' && (
                  <>
                    <Label style={{ fontSize: '1rem' }}>
                      { ReactHTMLParser( json.label )}
                      {
                        json.required && (
                          <span className='text-danger'>*</span>
                        )
                      }
                      {
                        mode !== 'view' && (
                          <CustomButton
                            type={ 'edit' }
                            onClick={ onClickField }
                          />
                        )
                      }
                    </Label>
                    <Input
                      placeholder={ json.placeholder }
                      type={ json.variant }
                      disabled={ true }
                      onChange={ e => onChangeFieldForm( 'key', e.target.value )}
                      />
                  </>
                ) 
              }
            </FormGroup>
          </Form>
        )
      }
      {
        json.variant === 'file' && (
          <div 
            className='d-flex flex-wrap justify-content-between align-items-center mb-3'
          >
            
            <div className='d-flex'>
              <Label style={{ fontSize: '1rem' }}>
                { ReactHTMLParser( json.label )}
              </Label> 
              {
                mode !== 'view' && (
                  <CustomButton
                    type={ 'edit' }
                    onClick={ onClickField }
                  />
                )
              }
            </div>
            <Button style={{ height: 'fit-content' }}>
              {  ReactHTMLParser( json.btn_label ) }
            </Button>
          </div>
        )
      }
    </div>
  )
}


export default DragField;