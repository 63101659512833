import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Get, Post, Put, Delete } from 'utils/axios'
import { requestError, requestSuccess } from 'utils/requestHandler'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      cookieContent: [],
      selectedContent: null,

      showCreateContentModal: false,
      showDeleteContentModal: false,
      showUpdateContentModal: false
    }

    onChangeContentHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })

    getContent = () => {
      Get(
        `/cookie_contents`,
        this.getContentSuccess,
        this.getContentError,
        this.load
      )
    }
    getContentSuccess = payload => {
      let tempPayload = _.map( payload, item => {
        return ({
          ... item, 
          cookie_category: _.find( this.props.categories, { id: item.cookie_category_id })
        })
      })
      this.setState({ cookieContent: tempPayload })
    }
    getContentError = error => requestError( error )

    getSelectedContent = id => Get(
      `/cookie_contents/${ id }`,
      this.getSelectedContentSuccess,
      this.getSelectedContentError,
      this.load
    )
    getSelectedContentSuccess = payload => this.setState({ selectedContent: payload })
    getSelectedContentError = error => requestError ( error )

    createContent = dataToSubmit=> Post(
      `/cookie_contents`,
      dataToSubmit,
      this.createContentSuccess,
      this.createContentError,
      this.load
    )
    createContentSuccess = () => {
      this.getContent()
      this.setState({ showCreateContentModal: false })
      requestSuccess( 'New Content has been created successfully.' )
    }
    createContentError = error => requestError( error )

    deleteContent = id => Delete( 
      `/cookie_contents/${ id }`,
      this.deleteContentSuccess,
      this.deleteContentError,
      this.load
    )
    deleteContentSuccess = () => {
      this.getContent()
      this.setState({ showDeleteContentModal: false })
      requestSuccess( 'Content has been deleted succesfully.' )
    }
    deleteContentError = error => requestError( error )

    updateContent = ( id, dataToSubmit ) => Put(
      `/cookie_contents/${ id }`,
      dataToSubmit,
      this.updateContentSuccess,
      this.updateContentError,
      this.load
    )
    updateContentSuccess = payload => {
      this.getContent()
      this.getSelectedContent( payload.id )
      requestSuccess( 'Content has been updated successfully.' )
    }
    updateContentError = error => requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          cookieContent={ this.state.cookieContent }
          onLoadContent={ this.state.loading }
          selectedContent={ this.state.selectedContent }
          
          showCreateContentModal={ this.state.showCreateContentModal }
          showDeleteContentModal={ this.state.showDeleteContentModal }
          showUpdateMContentodal={ this.state.showUpdateMContentodal }

          getContent={ this.getContent }
          getSelectedContent={ this.getSelectedContent }
          createContent={ this.createContent }
          deleteContent={ this.deleteContent }
          updateContent={ this.updateContent }
          onChangeContentHOC={ this.onChangeContentHOC }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC