import React, { Component } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { 
  Modal, ModalHeader, ModalBody,
  Row, Col, 
  Card, CardBody, 
  Button, UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'
import TransComponent from 'components/Trans'
import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import FormBuilderForm from './Form/index'
import BKPTable from 'components/Table'

import FormsHOC from './action'

class Forms extends Component {

  componentDidMount = () => this.props.getForms()

  render = () => {
    return (
      <>
        <PageTitle
          heading={ <TransComponent i18nKey="form.title.form_lang"/> }
          subheading={ <TransComponent i18nKey="form.desc.form_lang"/> }
          icon="pe-7s-culture icon-gradient bg-happy-itmeo"
          buttons={[
            {
              color: 'primary',
              onClick: () => {
                this.props.onChangeFormHOC( 'showCreateModal', true )
              },
              content: <TransComponent i18nKey={ `form.title.create`}/>
            }
          ]}
        />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md={12}>
              <Card className="main-card mb-3">
                <CardBody>
                  <BKPTable
                    pageSize={ 10 }
                    data={ this.props.forms }
                    columns={[
                      {
                        Header: <TransComponent i18nKey="form.fields.lang_key"/>,
                        accessor: 'language_key'
                      },
                      {
                        Header: <TransComponent i18nKey="form.fields.lang_label"/>,
                        accessor: 'language_value'
                      },
                      {
                        Header: <TransComponent i18nKey="general.action"/>,
                        accessor: 'id',
                        Cell: (row) => {
                          return (
                            <>
                              <Button
                                id={ `ViewForm_${ row.original.id }`}
                                className="mr-2 btn-icon btn-icon-only"
                                color="primary"
                                onClick={() => this.props.getSelectedForm( row.original.id )}
                              >
                                <i className={ `btn-icon-wrapper pe-7s-pen` }> </i>
                              </Button>
                              <UncontrolledTooltip target={ `ViewForm_${ row.original.id }`} placement="top">
                                <TransComponent i18nKey="form.tooltip.edit_form_translation"/>
                              </UncontrolledTooltip>
                            </>
                          )
                        }
                      }
                    ]
                  }
                  showPagination={ true } />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
        <Modal
          isOpen={ this.props.showCreateModal }
          size={ 'xl' }
          style={{ maxWidth: '100%', margin: 0 }}
          >
          <ModalHeader toggle={ () => this.props.onChangeFormHOC( 'showCreateModal', false )}>
            <TransComponent i18nKey={ 'form.title.create' }/>
          </ModalHeader>
          <ModalBody style={{ position: 'relative' }}>
            <FormBuilderForm
              mode={ 'create' }
              onLoadForms={ this.props.onLoadForms }
              selectedForm={ this.props.selectedForm }
              onClickSubmit={ this.props.createForm }
            />
          </ModalBody>
          { this.props.onLoadForms && <LoadingOverlay />}
        </Modal>
        <Modal
          isOpen={ this.props.showUpdateModal }
          size={ 'xl' }
          style={{ maxWidth: '100%', margin: 0 }}
          >
          <ModalHeader toggle={ () => this.props.onChangeFormHOC( 'showUpdateModal', false )}>
            <TransComponent i18nKey={ 'form.title.update' }/>
          </ModalHeader>
          <ModalBody style={{ position: 'relative' }}>
            <FormBuilderForm
              mode={ 'edit' }
              onLoadForms={ this.props.onLoadForms }
              selectedForm={ this.props.selectedForm }
              onClickSubmit={ this.props.updateForm }
            />
          </ModalBody>
          { this.props.onLoadForms && <LoadingOverlay />}
        </Modal>
        { this.props.onLoadForms && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  FormsHOC
)(Forms)