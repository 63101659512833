import React, { useState } from 'react'
import {
  FormGroup, Label, Input, Form, Button
} from 'reactstrap'

import TransComponent from 'components/Trans'

function AddLangModal({
  onSubmit
}) {
  const [ langVal, setLangVal ] = useState( '' )
  const [ langLabel, setLangLabel ] = useState( '' )

  return (
    <>
      <Form>
        <FormGroup>
          <Label>
            <TransComponent i18nKey={ `general.value` }/>
          </Label>
          <Input
            value={ langVal }
            type={ 'text' }
            onChange={ e => setLangVal( e.target.value )}
          />
        </FormGroup>
        <FormGroup>
          <Label>
            <TransComponent i18nKey={ `general.label` }/>
          </Label>
          <Input
            value={ langLabel }
            type={ 'text' }
            onChange={ e => setLangLabel( e.target.value )}
          />
        </FormGroup>
      </Form>
      <Button 
        disabled={ !langLabel || !langVal }
        color={ 'primary' } onClick={ () => onSubmit({
          label: langLabel,
          value: langVal
        })}
      >
        <TransComponent i18nKey={ `general.submit` }/>
      </Button>
    </>
  )
}

export default AddLangModal