import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Get, Post, Put, Delete } from 'utils/axios'
import { requestError, requestSuccess } from 'utils/requestHandler'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      companies: [],
      selectedCompany: {},

      showCreateModal: false,
      showDeleteModal: false,
      showUpdateModal: false
    }

    onChangeCompanyHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })

    getCompanies = () => Get(
      `/companies`,
      this.getCompanysSuccess,
      this.getCompanysError,
      this.load
    )
    getCompanysSuccess = payload => this.setState({ companies: payload })
    getCompanysError = error => requestError( error )

    getSelectedCompany = id => Get(
      `/companies/${ id }`,
      this.getSelectedCompanySuccess,
      this.getSelectedCompanyError,
      this.load
    )
    getSelectedCompanySuccess = payload => this.setState({ 
      selectedCompany: payload,
      showUpdateModal: true
    })
    getSelectedCompanyError = error => requestError ( error )

    createCompany = dataToSubmit => Post(
      `/companies`,
      dataToSubmit,
      this.createCompanySuccess,
      this.createCompanyError,
      this.load
    )
    createCompanySuccess = () => {
      this.getCompanies()
      this.setState({ showCreateModal: false })
      requestSuccess( 'New company has been created successfully.' )
    }
    createCompanyError = error => requestError( error )

    deleteCompany = id => Delete( 
      `/companies/${ id }`,
      this.deleteCompanySuccess,
      this.deleteCompanyError,
      this.load
    )
    deleteCompanySuccess = () => {
      this.getCompanies()
      this.setState({ showDeleteModal: false })
      requestSuccess( 'Company has been deleted succesfully.' )
    }
    deleteCompanyError = error => requestError( error )

    updateCompany = dataToSubmit => Put(
      `/companies/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateCompanySucces,
      this.updateCompanyError,
      this.load
    )
    updateCompanySucces = payload => {
      this.getCompanies()
      this.getSelectedCompany( payload.id )
      requestSuccess( 'Company has been updated successfully.' )
    }
    updateCompanyError = error => requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          companies={ this.state.companies }
          selectedCompany={ this.state.selectedCompany }
          onLoadCompanies={ this.state.loading }
          
          showCreateModal={ this.state.showCreateModal }
          showDeleteModal={ this.state.showDeleteModal }
          showUpdateModal={ this.state.showUpdateModal }

          getCompanies={ this.getCompanies }
          getSelectedCompany={ this.getSelectedCompany }
          createCompany={ this.createCompany }
          deleteCompany={ this.deleteCompany }
          updateCompany={ this.updateCompany }
          onChangeCompanyHOC={ this.onChangeCompanyHOC }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC