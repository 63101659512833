import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import _ from 'lodash'
import {
  ModalHeader, ModalBody,
  Modal
} from 'reactstrap'
import { BsFillFileEarmarkExcelFill } from 'react-icons/bs'
import { GiOpenFolder } from 'react-icons/gi'

import TransComponent from 'components/Trans'
import DetailsTabs from './DetailTabs/index'
import AttachmentContent from './Attachment'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ConfirmationModal from 'components/Modal/confirmation'
import CompanyDetailsModal from '../Modal/CompanyDetails'
import AdminContent from './AdminContent'

import CompanyUserHOC from '../actions/user'
import TypeHOC from '../actions/case_type'

import MessageHOC from '../actions/message'
import AttachmentHOC from '../actions/case_attachment'

import '../index.scss'

const Details = ({
  onClose,
  userType,
  companies,

  onLoadMessage,
  onLoadCase,
  onLoadCaseTypes,
  showCloseCaseModal,

  getCompanyUser,

  ... props
}) => {

  const [ showAttachment, setShowAttachment ] = useState( false )
  const [ showCompanyDetails, setCompanyDetails ] = useState( false )

  useEffect(() => {
    Promise.all([
      props.getCaseAttachement( props.selectedCase.id )
    ]).then(() => {
      props.getMessage( props.selectedCase.id )
    })
  }, [])

  useEffect(() => {
    let tempCombined = [
      ... props.caseMessages,
      ... props.caseAttachements
    ]

    tempCombined = _.orderBy( tempCombined, 'createAtUnix', 'asc' )
    props.onChangeMessageHOC( 'caseMessages', tempCombined )
  }, [ props.caseAttachements ])
  
  useEffect(() => {
    const { company_id } = props.selectedCase

    company_id && getCompanyUser( company_id )
    props.getCaseType( props.selectedCase.id )
  }, [ props.selectedCase ])
  
  return (
    <>
      <ModalHeader 
        style={{ fontWeight: 'bold' }} 
        toggle={ userType === 'non-case' ? () => onClose() : false }
      >
        <div style={{ fontWeight: 500 }}>
          <div className='d-flex flex-wrap'>
            <GiOpenFolder style={{ width: 25, height: 25, marginRight: 7 }}/>
            <h5>
              <TransComponent i18nKey="lawyer_case.fields.file_case_id"/>
              { ` - ${ props.selectedCase.uid }` }
            </h5>
          </div>
          {
            props.selectedCase.is_closed && (
              <p 
                className="badge badge-danger mb-0" 
                style={{ fontSize: '0.8rem', textTransform: 'capitalize', padding: '0.5rem 0.8rem' }}
              >
                <BsFillFileEarmarkExcelFill style={{ width: 17, height: 17 }} className='mr-1'/>
                <TransComponent i18nKey="lawyer_case.tabs.case_closed"/>
              </p>
            )
          }
        </div>
      </ModalHeader>
      <ModalBody>
        {
          userType === 'non-case' && (
            <>
              <AdminContent
                selectedCase={ props.selectedCase }
                setShowAttachment={ setShowAttachment }
                setCompanyDetails={ setCompanyDetails }
                { ... props }
              />
              <hr className='mt-4'/>
            </>
          )
        }
        <DetailsTabs
          { ... props }
          userType={ userType }
        />
      </ModalBody>
      {
        ( props.onLoadUser || onLoadCaseTypes || onLoadCase ) && (
          <LoadingOverlay/>
        )
      }
      <Modal size='xl' isOpen={ showAttachment }>
        <AttachmentContent
          { ... props }
          onClose={ () => setShowAttachment( false )}
        />
      </Modal>
      <Modal size='xl' isOpen={ showCompanyDetails }>
        <CompanyDetailsModal
          selectedCompany={ props.selectedCase?.company_info }
          onClose={ () => setCompanyDetails( false )}
        />
      </Modal>
      <ConfirmationModal
        open={ showCloseCaseModal }
        titleKey={ 'lawyer_case.title.close_case' } 
        messageKey={ 'lawyer_case.desc.close_case' } 
        onClose={ () => props.onChangeCaseHOC( 'showCloseCaseModal', false )}
        onClickConfirm={ () => {
          props.updateCase({
            ... props.selectedCase,
            is_closed: true
          })
        }} 
      />
    </>
  )
}

export default compose(
  CompanyUserHOC,
  TypeHOC,
  AttachmentHOC,
  MessageHOC
)( Details );