import { MdFormatAlignLeft, MdReport  } from 'react-icons/md'
import { RiPencilRuler2Fill } from 'react-icons/ri'
import { FaUserSecret } from 'react-icons/fa'
import { GiPartyPopper } from 'react-icons/gi'

export const DefaultJSONData = {
  "form_content": {
    "title": {
      "key": "whistle_blower_msg",
      "label": "<h2>Whistleblower Message</h2>"
    },
    "content": [
      {
        "type": "field",
        "variant": "textarea",
        "key": "description",
        "placeholder": "",
        "label": "<span>What is your suspicion?</span>",
      },
      {
        "type": "field",
        "variant": "file",
        "key": "bkp-case-attachment",
        "placeholder": "",
        "label": "<span>Case Attachment</span>",
        "btn_label": "<span>Upload file and attachment</span>"
      },
      {
        "type": "field",
        "variant": "text",
        "key": "company_department",
        "placeholder": "",
        "label": "<span>Please give the name of the affected department</span>"
      },
      {
        "type": "field",
        "variant": "text",
        "key": "country",
        "placeholder": "",
        "label": "<span>In which country did the incident take place ?</span>"
      },
      {
        "type": "field",
        "variant": "text",
        "key": "city",
        "placeholder": "",
        "label": "<span>In which city did the incident occur?</span>"
      },
      {
        "type": "field",
        "variant": "checkbox",
        "key": "is_anonymous",
        "placeholder": "",
        "label": "<span>Stay anonymous</span>"
      },
      {
        "type": "card",
        "key": "bkp-form-not-anonymous",
        "label": "<h5><strong>Contact information</h5></strong>",
        "content": [
          {
            "type": "field",
            "variant": "text",
            "key": "email",
            "placeholder": "",
            "label": "<span>Email</span>" 
          },
          {
            "type": "field",
            "variant": "text",
            "key": "name",
            "placeholder": "",
            "label": "<span>First and last name</span>"
          },
          {
            "type": "field",
            "variant": "text",
            "key": "contact",
            "placeholder": "",
            "label": "<span>Phone number</span>"
          }
        ]
      },
      {
        "type": "card",
        "key": "bkp_pw_card",
        "label": "<h5><strong>Password</h5></strong>",
        "content": [
          {
            "type": "field",
            "variant": "password",
            "key": "password",
            "placeholder": "",
            "label": "<span>Password</span>"
          },
          {
            "type": "field",
            "variant": "password",
            "key": "password_repeat",
            "placeholder": "",
            "label": "<span>Repeat Password</span>"
          }
        ]
      }
    ],
    "submit_button": {
      "type": 'button',
      "style": {
        "background": "#6390cf",
        "color": "#fff"
      },
      "label": '<span>Submit</span>'
    }
  },
  "terms_content": {
    "title": {      
      key: "safe_and_anonymous_title",
      label: '<h5><strong>Your data is safe & anonymous!</h5></strong>', 
    },
    "content": [
      {
        key: "hundred_anonymous_reporting",
        type: 'icon_list_item',
        label: '<span>100% anonymous reporting</span>', 
      },
      {
        key: "certified_data_security",
        type: 'icon_list_item',
        label: '<span>certified data security</span>', 
      },
      {
        key: "independent_ombudsman",
        type: 'icon_list_item',
        label: '<span>independent ombudsman service</span>', 
      },
      {
        key: "what_is_reporting_procedure",
        type: 'anchor',
        label: '<p><strong>What is the reporting procedure?</strong></p>', 
      },
      {
        key: "will_data_remain_anonymous",
        type: 'anchor',
        label: '<p><strong>Will my data remain anonymous?</strong></p>', 
      }
    ],
    "lawyer_contact_content": [
      {
        key: "give_hint_calling_following_number",
        label: '<p><strong>You can also give your hint directly, by calling the following number:</strong></p>', 
      },
      {
        key: "foc_from_all_german_network",
        label: '<small>Free of charge from all German landlines and all German mobile networks.</small>', 
      },
      {
        key: "working_hours",
        label: '<small>Mon to Thu: 08:00-17:00 - Fri 8:00 - 14:00</small>', 
      }
    ],
    "case_login_button": {
      "style": {
        "background": "#6390cf",
        "color": "#fff"
      },
      "label": '<span>Case Login</span>'
    }
  },
  "reporting_procedure_modal": {
    "title": {
      "key": "reporting_procedure_modal_title",
      "label": '<h5><strong>What is the reporting procedure?</h5></strong>'
    },
    "content": [
      {
        key: "secure_mailbox_option_upon_submit_report",
        label: '<ul><li>When you submit a report, you have the option of creating a secure mailbox so that you are available for further queries.</li></ul>',
      },
      {
        key: "keep_yourself_available",
        label: '<ul><li>We recommend keeping yourself available this way as we may not be able to close the case without further information from you.</ul></li>',
  
      },
      {
        key: "case_num_password_assigned_check_quesion_in_email",
        label: '<ul><li>When you create a mailbox, you will be assigned a case number and you will need to choose a password (PIN). You will need to use this case number and password (PIN) to gain access to the mailbox and to see if you have received any questions..</ul></li>',
  
      },
      {
        key: "mailbox_created_for_easier_communication",
        label: '<ul><li>Regardless of whether you remain anonymous or give your name, we ask you to create a mailbox. This makes it easier and safer for us to communicate with you.</ul></li>',
      },
      {
        key: "communication_with_us_will_remain_anonymous_if_your_wish",
        label: '<ul><li>If you wish, all communication with us will remain anonymous.</ul></li>',
      },
      {
        key: "communication_with_us_will_remain_anonymous_if_your_wish",
        label: '<ul><li>If you wish, all communication with us will remain anonymous.</ul></li>',
      }
    ]
  },
  "data_remain_anonymous_modal": {
    "title": {
      "key": "data_remain_anonymous_modal_title",
      "label": '<h5><strong>Will my data remain anonymous?<h5><strong>', 
      },    
    "content": [
      {
        key: "to_ensure_anonymity",
        label: '<p>To ensure your anonymity, you need to do the following:</p>',
      },
      {
        key: "do_not_report_from_employer_pc",
        label: `<ul><li>If possible, do not run the report from your employer's computer.</li></ul>`,
  
      },
      {
        key: "do_not_connect_to_company_network",
        label: '<ul><li>Do not use a PC that is connected to the company network / intranet of the company.</li></ul>',
      },
      {
        key: "enter_using_url_address_not_clicking_a_link",
        label: '<ul><li>Call up the reporting system by entering the URL address directly in the browser and not by clicking a link.</li></ul>',
      },
      {
        key: "do_not_write_personal_information_on_report",
        label: '<ul><li>Do not write personal information on the report</li></ul>',
      }
    ]
  },
  "case_success_content": {
    "content": [
      {
        key: "report_successfully_submitted",
        label: '<p>Your report was successfully submitted and will be processed promptly. With your password and case number you can follow up the case at any time and send us additional messages. Please keep your case number and password in a safe place. If lost, access cannot be restored for anonymous users.</p>',
      }    
    ],
    "download_case_summary_button": {
      "style": {
        "background": "#6390cf"
      },
      "label": '<span>Download Case Summary</span>'
    }
  }
}

export const FieldTypeOptions = [ 'text', 'textarea', 'file', 'checkbox', 'password' ]

export const TabEditOptions = [ 
  {
    value: 'form_content', 
    icon: <MdFormatAlignLeft/>,
  },
  {
    value: 'terms_content',
    icon: <RiPencilRuler2Fill/>
  },
  {
    value: 'reporting_procedure_modal', 
    icon: <MdReport/>
  },
  {
    value: 'data_remain_anonymous_modal', 
    icon: <FaUserSecret/>
  },
  {
    value: 'case_success_content', 
    icon: <GiPartyPopper/>
  }
]