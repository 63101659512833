import React, { useState, useEffect, useMemo } from 'react'
import { Row, Col } from 'reactstrap'
import { 
  Button, Modal,
  UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'
import { IoMdClose } from 'react-icons/io'

import RendererDesc from './Renderer/RendererDesc'
import AddLangModal from '../AddLangModal/index'
import AddTextFieldModal from './AddField/index'
import FormRenderer from './Renderer/FormRenderer'
import TermsContent from './Renderer/TermsContent'
import CaseSuccessContent from './Renderer/CaseSuccessContent'
import ReportingProcedure from './Renderer/ReportingProcedure'
import DataRemainAnonymous from './Renderer/DataRemainAnonymous'
import EditSectionContent from './EditSections'

import { DefaultJSONData, DefaultCard } from '../assets'
import { requestError, requestSuccess } from 'utils/requestHandler'

import ConfirmationModal from 'components/Modal/confirmation'
import TransComponent from 'components/Trans'

function Form({
  selectedForm,
  onClickSubmit
}) {
  const [ currentTab, setCurrentTab ] = useState( 'form_content' )

  const [ groupMode, setGroupMode ] = useState( false )
  const [ selectedToGroup , setSelectedGroup ] = useState([])

  const [ currentLang, setCurrentLang ] = useState( 'en' )

  const [ showAddLang, setAddLang ] = useState( false ) 
  const [ selectedDeleteLang, setDeleteLang ] = useState( null )

  const [ formData, onChangeFormData ] = useState( DefaultJSONData )
  
  const [ showAddField, setAddFieldModal ] = useState( false )

  const [ selectedDelete, setSelectedDelete ] = useState( null )
  const [ selectedDeletePath, setDeletePath ] = useState( null )

  const [ selectedEdit, setSelectedEdit ] = useState( null )

  const [ duplicatedKeyError, setDuplicatedKey ] = useState([])

  // for duplicate key checking
  let occupiedKeys = useMemo(() => {
    let tempKeys = []
    formData.form_content.content.map( contentChild => {
      if ( contentChild.type === 'card' ) {
        contentChild.content.map( cardField => {
          tempKeys.push( cardField.key )
        })
      } else {
        tempKeys.push( contentChild.key )
      }
    })

    return tempKeys
  }, [formData.form_content])

  useEffect(() => {
    selectedForm.form_json && 
    onChangeFormData( selectedForm.form_json )
  }, [ selectedForm ])

  useEffect(() => {
    if ( !groupMode ){
      setSelectedGroup([])
      setSelectedEdit( null )
    }
  }, [ groupMode ])

  useEffect(() => {
    !_.isEmpty( selectedEdit ) && 
    setDuplicatedKey([])
  }, [ selectedEdit ])

  useEffect(() => {
    const { language_options } = formData
    setCurrentLang( language_options[ language_options.length - 1 ].value )
  }, [formData.language_options])


  const onHandleChangeSelectedEdit = val => {
    Promise.all([
      setSelectedEdit( null )
    ]).then(() => setSelectedEdit( val ))
  }

  const onChangeButtonForm = ( key, val, fieldKey ) => {
    let tempForm = _.cloneDeep( formData )
    if ( fieldKey === 'download_case_summary_button' ){
      tempForm[ 'case_success_content' ][ fieldKey ][ key ] = val
    }

    if ( fieldKey === 'submit_button' ){
      tempForm[ 'form_content' ][ fieldKey ][ key ] = val
    }

    if ( fieldKey === 'case_login_button' ){
      tempForm[ 'terms_content' ][ fieldKey ][ key ] = val
    }

    onChangeFormData( tempForm )
  }

  const onChangeFieldForm = ( key, val, fieldIndex, cardIndex, contentContext ) => {
    let tempContent = _.cloneDeep( formData[ currentTab ][ contentContext ] )

    if ( cardIndex ){
      tempContent[ cardIndex ][ contentContext ][ fieldIndex ][ key ] = val
    } else {
      tempContent[ fieldIndex ][ key ] = val
    }

    onChangeFormData({
      ... formData,
      [ currentTab ]: {
        ... formData[ currentTab ],
        [ contentContext ]: tempContent
      }
    })
  }

  //on handle grouping fields into a new card and selecting grouping fields
  const onHandleCheckGroup = val => {
    let tempGroup = _.cloneDeep( selectedToGroup )
    
    if ( _.findIndex( tempGroup, item => item === val ) > -1 ){
      _.remove( tempGroup, item => item === val )
    } else {
      tempGroup.push( val )
    }

    setSelectedGroup( tempGroup )
  }
  
  const onHandleGroup = val => {
    let tempCard = _.cloneDeep( DefaultCard )
    let tempContent = _.cloneDeep( formData.form_content.content )

    formData.form_content.content.map(( childContent ) => {
      if ( val.indexOf( childContent.key ) > -1 ){
        tempCard.content.push( childContent )
      }
    })

    tempContent = _.filter( tempContent, childTemp => val.indexOf( childTemp.key ) < 0 )
    tempContent.push( tempCard )
    onChangeFormData({
      ... formData,
      form_content: {
        ... formData.form_content,
        content: tempContent
      }
    })
    setGroupMode( false )
  }
  
  //add and remove fields
  const onAddNewField = val => {
    let tempContent = [
      val,
      ... formData.form_content.content,
    ]
    
    onChangeFormData({
      ... formData,
      form_content: {
        ... formData.form_content,
        content: tempContent
      }
    })
    requestSuccess( <TransComponent i18nKey={ 'form.desc.add_field_success' }/> )
    setAddFieldModal( false )
  }

  // submit new lang
  const onSubmitAddLang = val => {
    setAddLang( false )
    let tempFormData = _.cloneDeep( formData )
    tempFormData[ 'language_options' ].push( val ) 

    tempFormData.form_content[ 'title' ][ 'label' ][ val.value ] = tempFormData.form_content[ 'title' ][ 'label' ][ 'en' ]
    tempFormData.terms_content[ 'title' ][ 'label' ][ val.value ] = tempFormData.terms_content[ 'title' ][ 'label' ][ 'en' ]
    tempFormData.reporting_procedure_modal[ 'title' ][ 'label' ][ val.value ] = tempFormData.reporting_procedure_modal[ 'title' ][ 'label' ][ 'en' ]
    tempFormData.data_remain_anonymous_modal[ 'title' ][ 'label' ][ val.value ] = tempFormData.data_remain_anonymous_modal[ 'title' ][ 'label' ][ 'en' ]

    tempFormData.form_content[ 'submit_button' ][ 'label' ][ val.value ] = tempFormData.form_content[ 'submit_button' ][ 'label' ][ 'en' ]
    tempFormData.terms_content[ 'case_login_button' ][ 'label' ][ val.value ] = tempFormData.terms_content[ 'case_login_button' ][ 'label' ][ 'en' ] 
    tempFormData.case_success_content[ 'download_case_summary_button' ][ 'label' ][ val.value ] = tempFormData.case_success_content[ 'download_case_summary_button' ][ 'label' ][ 'en' ]

    tempFormData.form_content.content = _.map( tempFormData.form_content.content, formChild => (
      onHandleLangChange( formChild, val.value, 'add' )
    ))
    tempFormData.terms_content.content = _.map( tempFormData.terms_content.content, formChild => (
      onHandleLangChange( formChild, val.value, 'add' )
    ))

    tempFormData.terms_content.lawyer_contact_content = _.map( tempFormData.terms_content.lawyer_contact_content, formChild => (
      onHandleLangChange( formChild, val.value, 'add' )
    ))

    tempFormData.reporting_procedure_modal.content = _.map( tempFormData.reporting_procedure_modal.content, formChild => (
      onHandleLangChange( formChild, val.value, 'add' )
    ))
    tempFormData.data_remain_anonymous_modal.content = _.map( tempFormData.data_remain_anonymous_modal.content, formChild => (
      onHandleLangChange( formChild, val.value, 'add' )
    ))
    tempFormData.case_success_content.content = _.map( tempFormData.case_success_content.content, formChild => (
      onHandleLangChange( formChild, val.value, 'add' )
    ))

    onChangeFormData( tempFormData )
  }

  const HandleAddTranslatedLangChange = ( tempData, langVal, tempContent ) => {
    let tempFormData = _.cloneDeep( tempData )
    let tempIndex = _.findIndex( tempContent, { key: tempFormData.key })

    if ( tempFormData?.type === 'card' ){
      tempFormData.content = _.map( tempFormData.content, contentChild => {
        return HandleAddTranslatedLangChange( contentChild, langVal, tempContent[ tempIndex ].content )
      })
    }

    tempFormData.label[ langVal ] = tempIndex > -1 
      ? tempContent[ tempIndex ][ 'label' ]
      : tempFormData.label[ 'en' ]

    if ( tempFormData.variant === 'file' ){
      tempFormData.btn_label[ langVal ] = tempIndex > -1 
        ? tempContent[ tempIndex ][ 'btn_label' ]
        : tempFormData.btn_label[ 'en' ]
    }

    if ( tempFormData.placeholder ){
      tempFormData.placeholder[ langVal ] = tempIndex > -1 
        ? tempContent[ tempIndex ][ 'placeholder' ]
        : tempFormData.placeholder[ 'en' ]
    }

    return tempFormData;
  }

  const onSubmitAssign = val => {
    const { 
      language_value,
      language_key,
      form_json
    } = val

    let lang = {
      label: language_value,
      value: language_key
    }
    let tempFormData = _.cloneDeep( formData )

    tempFormData[ 'language_options' ].push( lang ) 
    tempFormData.form_content.title[ 'label' ][ lang.value ] = form_json.form_content.title.label
    tempFormData.terms_content.title[ 'label' ][ lang.value ] = form_json.terms_content.title.label
    tempFormData.reporting_procedure_modal.title[ 'label' ][ lang.value ] = form_json.reporting_procedure_modal.title.label
    tempFormData.data_remain_anonymous_modal.title[ 'label' ][ lang.value ] = form_json.data_remain_anonymous_modal.title.label

    tempFormData.form_content[ 'submit_button' ][ 'label' ][ lang.value ] = form_json.form_content[ 'submit_button' ][ 'label' ]
    tempFormData.terms_content[ 'case_login_button' ][ 'label' ][ lang.value ] = form_json.terms_content[ 'case_login_button' ][ 'label' ]
    tempFormData.case_success_content[ 'download_case_summary_button' ][ 'label' ][ lang.value ] = form_json.case_success_content[ 'download_case_summary_button' ][ 'label' ]

    tempFormData.form_content.content = _.map( tempFormData.form_content.content, formChild => (
      HandleAddTranslatedLangChange( formChild, lang.value, form_json.form_content.content )
    ))

    tempFormData.terms_content.lawyer_contact_content = _.map( tempFormData.terms_content.lawyer_contact_content, formChild => (
      HandleAddTranslatedLangChange( formChild, lang.value, form_json.terms_content.lawyer_contact_content )
    ))

    tempFormData.terms_content.content = _.map( tempFormData.terms_content.content, formChild => (
      HandleAddTranslatedLangChange( formChild, lang.value, form_json.terms_content.content )
    ))

    tempFormData.reporting_procedure_modal.content = _.map( tempFormData.reporting_procedure_modal.content, formChild => (
      HandleAddTranslatedLangChange( formChild, lang.value, form_json.reporting_procedure_modal.content )
    ))

    tempFormData.data_remain_anonymous_modal.content = _.map( tempFormData.data_remain_anonymous_modal.content, formChild => (
      HandleAddTranslatedLangChange( formChild, lang.value, form_json.data_remain_anonymous_modal.content )
    ))
    
    tempFormData.case_success_content.content = _.map( tempFormData.case_success_content.content, formChild => (
      HandleAddTranslatedLangChange( formChild, lang.value, form_json.case_success_content.content )
    ))

    setAddLang( false )
    onChangeFormData( tempFormData )
  }

  const onHandleRemoveField = () => {
    setSelectedEdit( null )

    let tempContent = _.cloneDeep( formData.form_content[ 'content' ] )
    if ( selectedDeletePath.cardIndex ){
      let temp = _.cloneDeep( formData.form_content[ 'content' ][ selectedDeletePath.cardIndex ][ 'content' ] )
      temp.splice( selectedDeletePath.fieldIndex, 1 )

      tempContent[ selectedDeletePath.cardIndex ][ 'content' ] = temp
    } else {
      tempContent.splice( selectedDeletePath.fieldIndex, 1 )
    }

    onChangeFormData({
      ... formData,
      form_content: {
        ... formData.form_content,
        content: tempContent
      }
    })
    requestSuccess( <TransComponent i18nKey={ 'form.desc.remove_field_success' }/> )
    setDeletePath( null )
    setSelectedDelete( null )
  }

  //add and remove langs
  const onHandleLangChange = ( tempData, val, mode ) => {
    let tempFormData = _.cloneDeep( tempData )

    if ( tempFormData?.type === 'card' ){
      tempFormData.content = _.map( tempFormData.content, contentChild => {
        return onHandleLangChange( contentChild, val, mode )
      })
    }
    
    if ( mode === 'add' ){
      tempFormData.label[ val ] = tempFormData.label[ 'en' ]

      if ( tempFormData.variant === 'file' ){
        tempFormData.btn_label[ val ] = tempFormData.btn_label[ 'en' ]
      }

      if ( tempFormData.placeholder ){
        tempFormData.placeholder[ val ] = tempFormData.placeholder[ 'en' ]
      }
    } else {
      delete tempFormData.label[ val ]

      tempFormData.variant === 'file' && 
      delete tempFormData.btn_label[ val ]

      tempFormData.placeholder && 
      delete tempFormData.placeholder[ val ]
    }
    return tempFormData;
  }

  const onHandleRemoveLang = () => {
    let tempFormData = _.cloneDeep( formData )
    _.remove( tempFormData[ 'language_options' ], lang => {
      if (lang.value === selectedDeleteLang){
        tempFormData.locale = formData.locale === lang.value
          ? 'de'
          : formData.locale
        return true
      }
    })

    delete tempFormData.form_content.title[ 'label' ][ selectedDeleteLang ]
    delete tempFormData.terms_content.title[ 'label' ][ selectedDeleteLang ]
    delete tempFormData.reporting_procedure_modal.title[ 'label' ][ selectedDeleteLang ]
    delete tempFormData.data_remain_anonymous_modal.title[ 'label' ][ selectedDeleteLang ]

    delete tempFormData.form_content.submit_button[ 'label' ][ selectedDeleteLang ]
    delete tempFormData.terms_content.case_login_button[ 'label' ][ selectedDeleteLang ]
    delete tempFormData.case_success_content.download_case_summary_button[ 'label' ][ selectedDeleteLang ]
    
    tempFormData.form_content.content = _.map( tempFormData.form_content.content, formChild => (
      onHandleLangChange( formChild, selectedDeleteLang, 'remove' )
    ))
    tempFormData.terms_content.content = _.map( tempFormData.terms_content.content, formChild => (
      onHandleLangChange( formChild, selectedDeleteLang, 'remove' )
    ))

    tempFormData.terms_content.lawyer_contact_content = _.map( tempFormData.terms_content.lawyer_contact_content, formChild => (
      onHandleLangChange( formChild, selectedDeleteLang, 'remove' )
    ))

    tempFormData.reporting_procedure_modal.content = _.map( tempFormData.reporting_procedure_modal.content, formChild => (
      onHandleLangChange( formChild, selectedDeleteLang, 'remove' )
    ))
    tempFormData.data_remain_anonymous_modal.content = _.map( tempFormData.data_remain_anonymous_modal.content, formChild => (
      onHandleLangChange( formChild, selectedDeleteLang, 'remove' )
    ))
    tempFormData.case_success_content.content = _.map( tempFormData.case_success_content.content, formChild => (
      onHandleLangChange( formChild, selectedDeleteLang, 'remove' )
    ))

    onChangeFormData( tempFormData )
    setDeleteLang( null )
  }

  // on handle submitting form

  function toFindDuplicates(arry) {
    const set = new Set(arry);

    const duplicates = arry.filter(item => {
      if (set.has(item)) {
          set.delete(item);
      } else {
          return item;
      }
    });

    return duplicates
}

  const onHandleSubmit = () => {
    let temp = toFindDuplicates( occupiedKeys )
    setDuplicatedKey( temp )
    setSelectedEdit( null )

    if ( temp.length > 0 ) {
      setCurrentTab( 'form_content' )
      requestError( <TransComponent i18nKey={ 'form.desc.field_key_duplicate' }/>)
      
      return 
    } 

    onClickSubmit({
      ... selectedForm,
      form_json: formData,
      html_string: '-'
    })
  }

  return (
    <>
      <div className='d-flex flex-wrap align-items-center mb-2'>
        {
          currentTab === 'form_content' && (
            <>
              <Button 
                id={ 'GroupButton' }
                color={ groupMode ? 'danger' : 'secondary' }
                style={{ padding: '0.275rem 0.5rem' }}
                onClick={() => setGroupMode( !groupMode )}
              >
                <TransComponent i18nKey={ !groupMode ? 'general.select' : 'form.desc.quit_group_mode' }/>
              </Button>
              <UncontrolledTooltip target="GroupButton" placement="top">
                <TransComponent i18nKey={ 'form.desc.edit_mode_btn' }/>
              </UncontrolledTooltip>
              {
                !groupMode && (
                  <Button 
                    color={ 'primary' } className={ 'ml-2' }
                    onClick={ () => setAddFieldModal( true )}>
                    <TransComponent i18nKey={ 'form.desc.add_field' }/>
                  </Button>
                )
              }
            </>
          )
        }
        {
          groupMode && (
            <Button 
              color='secondary'
              className='ml-2'
              disabled={ selectedToGroup.length < 1 }
              onClick={ () => onHandleGroup( selectedToGroup )}
            >
              <TransComponent i18nKey={ 'general.combine' }/>
            </Button>
          )
        }
        <div 
          className="d-flex flex-wrap ml-auto justify-content-end p-2 rounded" 
          style={{ width: 'max-content', background: 'rgba(0,0,0,0.2)' }}
        >
          {
            formData.language_options.map(( option, index )=> (
              <Button
                key={ index }
                size={ 'sm' }
                style={{ textTransform: 'uppercase', position: 'relative' }}
                className={ index !== formData.language_options.length - 1 ? 'mr-2' : '' }
                onClick={() => setCurrentLang( option.value )}
                color={ currentLang === option.value ? 'primary' : 'secondary' }
              >
                {
                  [ 'en', 'de' ].indexOf( option.value ) < 0 && (
                    <div 
                      style={{ 
                        position: 'absolute', top: -18, right: -18,
                        padding: '1px 5px', cursor: 'pointer'
                      }}
                      className={ 'bg-danger text-white rounded-circle' }
                      onClick={ () => setDeleteLang( option.value )}
                    >
                      <IoMdClose style={{ width: 10, height: 10, marginBottom: 2 }}/>
                    </div>
                  )
                }
                { option.value }
              </Button>
            ))
          }
          <Button
            size={ 'sm' }
            className={ 'ml-4' }
            onClick={() => setAddLang( true )}
            color={ 'light' }
          >
            <strong>+</strong>
          </Button>
        </div>
      </div>
      <RendererDesc currentTab={ currentTab }/>
      <hr/>
      <Row>
				<Col 
					xl={ !groupMode ? 9 : 12 } lg={ !groupMode ? 8 : 12 } 
					md={ !groupMode ? 7 : 12 } sm={ 12 } 
					className={ 'bkp-form-renderer-col-7 bkp-form-renderer-col' }
				>		
          {
            currentTab === 'form_content' && (
              <FormRenderer
                formData={ formData }
                groupMode={ groupMode }
                currentLang={ currentLang }
                selectedEdit={ selectedEdit }
                selectedToGroup={ selectedToGroup }
                duplicatedKeyError={ duplicatedKeyError }
        
                setSelectedDelete={ setSelectedDelete }
                setDeletePath={ setDeletePath }
                onChangeFormData={ onChangeFormData }
                onHandleChangeSelectedEdit={ onHandleChangeSelectedEdit }
                onChangeFieldForm={ onChangeFieldForm }
                onHandleCheckGroup={ onHandleCheckGroup }
              />
            )
          }
          {
            currentTab === 'terms_content' && (
              <TermsContent
                currentLang={ currentLang }
                formData={ formData }
                selectedEdit={ selectedEdit }

                onHandleChangeSelectedEdit={ onHandleChangeSelectedEdit }
                onChangeFieldForm={ onChangeFieldForm }
              />
            )
          }
          {
            currentTab === 'reporting_procedure_modal' && (
              <ReportingProcedure
                currentLang={ currentLang }
                formData={ formData }
                selectedEdit={ selectedEdit }

                onHandleChangeSelectedEdit={ onHandleChangeSelectedEdit }
                onChangeFieldForm={ onChangeFieldForm }
              />
            )
          }
          {
            currentTab === 'data_remain_anonymous_modal' && (
              <DataRemainAnonymous
                currentLang={ currentLang }
                formData={ formData }
                selectedEdit={ selectedEdit }

                onHandleChangeSelectedEdit={ onHandleChangeSelectedEdit }
                onChangeFieldForm={ onChangeFieldForm }
              />
            )
          }
          {
            currentTab === 'case_success_content' && (
              <CaseSuccessContent
                currentLang={ currentLang }
                formData={ formData }
                selectedEdit={ selectedEdit }
                
                onHandleChangeSelectedEdit={ onHandleChangeSelectedEdit }
                onChangeFieldForm={ onChangeFieldForm }
              />
            )
          }
        </Col>
        {
          !groupMode && (
            <Col 
              xl={ 3 } lg={ 4 } md={ 5 } sm={ 12 } 
              className={ 'bkp-form-renderer-col' }
            >
              <EditSectionContent
                currentTab={ currentTab }
                formData={ formData }
                currentLang={ currentLang }
                selectedEdit={ selectedEdit }
                
                setCurrentTab={ val => {
                  setCurrentTab( val )
                  setSelectedEdit( null )
                  setGroupMode( false )
                }}
                setSelectedEdit={ setSelectedEdit }
                onChangeFormData={ onChangeFormData }
                onChangeFieldForm={ onChangeFieldForm }
                onChangeButtonForm={ onChangeButtonForm }
              />
            </Col>
          )
        }
        {/*  handle editing section content */}
      </Row>
      <Button 
        color={ 'primary' } className={ 'mt-4' }
        onClick={ () => onHandleSubmit()}
      >
        <TransComponent i18nKey={ `general.submit` }/>
      </Button>

      <Modal isOpen={ showAddLang }>
        <AddLangModal
          onSubmit={ val => onSubmitAddLang( val )}
          onSubmitAssign={ val => onSubmitAssign( val )}
          onClose={ () => setAddLang( false )}
        />
      </Modal>
      <Modal isOpen={ showAddField } size={ 'xl' }>
        <AddTextFieldModal
          formData={ formData }
          occupiedKeys={ occupiedKeys }
          onClose={ () => setAddFieldModal( false )}
          onSubmit={ onAddNewField } 
        />
      </Modal>

      <ConfirmationModal
        open={ selectedDelete ? true : false }
        titleKey={ 'form.title.delete_field' }
        messageKey={ 'form.desc.delete_field' }
        onClickConfirm={ () => onHandleRemoveField()}
        onClose={ () => {
          setDeletePath( null )
          setSelectedDelete( null )
        }}
      />
      <ConfirmationModal
        open={ selectedDeleteLang ? true : false }
        loading={ false }
        titleKey={ 'form.title.delete_lang' }
        messageKey={ 'form.desc.delete_lang' }
        onClose={ () => setDeleteLang( null )}
        onClickConfirm={ () => onHandleRemoveLang()}
      />
    </>
  )
}

export default Form;