import React, { useCallback, useState, useMemo, useEffect } from 'react'
import {
  Row, Col,
  Form, FormGroup, Label, Input, Button,
  ModalBody, ModalFooter, ModalHeader
} from 'reactstrap'
import _ from 'lodash'

import TransComponent from 'components/Trans'
import { useTranslation } from 'react-i18next'

import { 
  EditingLanguages,
  CookieCondition,
  StandardConditions,
  DefaultFormValue
} from './assets'

const CookieCategoryForm = ({
  mode,
  categories,
  onClose,
  selectedForm,
  onClickSubmit
}) => {

  const [ categoryForm, setCategoryForm ] = useState( DefaultFormValue )

  const { t, i18n } = useTranslation()

  const positionListInUse = useMemo(() => {
    let temp = {}
    categories.map( category => {
      temp[ category.id ] = category[ 'position']
    })
    return temp;
  }, [ categories ])

  const disabledSubmit = useCallback(() => {
    return (
      _.values( categoryForm ).some( val => {
        if ( typeof val === 'object' ){
          return _.values( val ).some( childVal => !childVal )
        }
        return !val 
      }) || 
      _.findIndex( _.keys( positionListInUse ), key => (
        +positionListInUse[ key ] === +categoryForm.position &&
        ( !categoryForm.id || ( categoryForm.id && +key !== categoryForm.id  ))
      )) > -1
    )

  }, [ categoryForm, positionListInUse ])
  
  useEffect(() => {
    if ( mode === 'edit' && selectedForm ){
      setCategoryForm( selectedForm )
    }
  }, [selectedForm])
  
  const updateCurrentCategory = useCallback(( key, val ) => {
    let tmpCookieCategory = _.cloneDeep( categoryForm )

    if( key === 'title' || key === 'description' ){
      tmpCookieCategory[ key ][ i18n.resolvedLanguage ] = val
    } else {
      tmpCookieCategory[ key ] = val
    }

    if( key === 'cookie_type' ){
      tmpCookieCategory.standard_condition = _.find( CookieCondition, { type : val } ).standardCondition
    }

    setCategoryForm( tmpCookieCategory )
  }, [ categoryForm ])
  
  return(
    <>
      <ModalHeader toggle={() => onClose()}>
        <TransComponent i18nKey={ `cookie_category.title.${ mode }` }/>
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label style={{ width: "max-content", marginRight: "1rem" }} >
              <TransComponent i18nKey={ 'general.current_editing_lang' }/>
            </Label>
            <div className='d-flex' style={{ gap: 10 }}>
            {
              EditingLanguages.map( lang => (
                <Button
                  color={ i18n.resolvedLanguage === lang ? 'primary' : 'secondary' }
                  onClick={ () => i18n.changeLanguage( lang ) }>{ lang }</Button>
              ))
            }
            </div>
          </FormGroup>
          <FormGroup>
            <Label>
              <TransComponent i18nKey={ 'general.title' }/>
            </Label>
            <Input
              type='text'
              value={ categoryForm.title[ i18n.resolvedLanguage ] }
              onChange={ e => updateCurrentCategory( 'title', e.target.value ) } />
          </FormGroup>
          <FormGroup>
            <Label>
              <TransComponent i18nKey={ 'general.description' }/>
            </Label>
            <Input
              type='text'
              value={ categoryForm.description[ i18n.resolvedLanguage ] }
              onChange={ e => updateCurrentCategory( 'description', e.target.value ) } />
            <small>
              <TransComponent i18nKey={ 'cookie_category.desc.assume_responsibility'}/>
            </small>
          </FormGroup>
          <Row>
            <Col md={ 6 } >
              <FormGroup>
                <Label>
                  <TransComponent i18nKey={ 'general.position' }/>
                </Label>
                <Input
                  type='number'
                  min={ 0 }
                  value={ categoryForm.position }
                  onChange={ e => updateCurrentCategory( 'position', parseInt( e.target.value ) ) } />
                {
                  _.findIndex( _.keys( positionListInUse ), key => (
                    +positionListInUse[ key ] === +categoryForm.position &&
                    ( !categoryForm.id || ( categoryForm.id && +key !== categoryForm.id  ))
                  )) > -1 && (
                    <small className='text-danger'>
                      <TransComponent i18nKey={ 'cookie_category.desc.position_taken'}/>
                    </small>
                  )
                }
              </FormGroup>
            </Col>
            <Col md={ 6 } >
              <FormGroup>
                <Label>
                  <TransComponent i18nKey={ 'cookie_category.fields.cookie_type'}/>
                </Label>
                <Input
                  type='select'
                  value={ categoryForm.cookie_type }
                  onChange={ e => updateCurrentCategory( 'cookie_type', e.target.value ) } >
                  { 
                    CookieCondition.map( item => (
                      <option value={ item.type } >
                        { t( `cookie_category.fields.${ item.i18nKey }` ) }
                      </option>
                    )) 
                  }
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label>
              <TransComponent i18nKey={ 'cookie_category.fields.standard_condition'}/>
            </Label>
            <Input
              type='select'
              disabled={ true }
              value={ categoryForm.standard_condition } >
              { StandardConditions.map( condition => (
                <option value={ condition.value }>
                  { t( `cookie_category.fields.${ condition.i18nKey }` ) }
                </option>
              )) }
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>
              <TransComponent i18nKey={ 'general.status' }/>
            </Label>
            <Input
              type='select'
              value={ categoryForm.status }
              onChange={ e => updateCurrentCategory( 'status', e.target.value ) }>
              <option value={ 'ACTIVE' }>{ t( `general.active` ) }</option>
              <option value={ 'INACTIVE' }>{ t( `general.inactive` ) }</option>
            </Input>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary" 
          disabled={ disabledSubmit() }
          onClick={() => onClickSubmit( categoryForm )}
        >
          <TransComponent i18nKey="general.submit"/>
        </Button>
      </ModalFooter>
    </>
  )
}

export default CookieCategoryForm