import React, { useEffect } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { 
    Modal, 
    Row, Col, Button, UncontrolledTooltip,
    Card, CardBody
} from 'reactstrap'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import TransComponent from 'components/Trans'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import BKPTable from 'components/Table'
import ContentForm from './form'
import ConfirmationModal from 'components/Modal/confirmation'
import { getItem } from 'utils/tokenStore'

import ContentHOC from './actions/index'
import CategoryHOC from './actions/category'

const Content = props => {

  useEffect(() => {
    props.getCategories()

    getItem( 'BPK_USER_TYPE' ) !== 'non-case' &&
    props.history.push( '/dashboard/case-mngmt' )
  }, [])

  useEffect(() => {
    props.getContent()
  }, [ props.categories ])

  const { i18n } = useTranslation()

  return (
    <>
      <PageTitle
        heading={ <TransComponent i18nKey={ `cookie_content.title.main`}/>}
        subheading={ <TransComponent i18nKey={ `cookie_content.desc.main`}/> }
        icon="pe-7s-user icon-gradient bg-happy-itmeo"
        buttons={[
          {
            color: 'primary',
            onClick: () => {
              props.onChangeContentHOC( 'showCreateContentModal', true )
            },
            content: <TransComponent i18nKey={ `cookie_content.title.create`}/>
          }
        ]}
      />
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}>
        <Row>
          <Col md={12}>
            <Card className="main-card mb-3">
              <CardBody>
                <BKPTable
                  pageSize={ 10 }
                  data={ props.cookieContent }
                  columns={[
                    {
                      Header: <TransComponent i18nKey={ 'general.title'}/>,
                      Cell: row => row.original.title[ i18n.resolvedLanguage ]
                    },
                    {
                      Header: <TransComponent i18nKey={ 'general.position'}/>,
                      accessor: 'position'
                    },
                    {
                      Header: <TransComponent i18nKey={ 'general.status'}/>,
                      Cell: row => (
                        <span className={ `badge badge-${ row.original.status === 'ACTIVE' ? 'success' : 'danger' }`}>
                          <TransComponent i18nKey={ `general.${ row.original.status.toLowerCase() }` }/>
                        </span>
                      )
                    },
                    {
                      Header: <TransComponent i18nKey={ 'cookie_content.fields.cookie_category' }/>,
                      Cell: row => row.original.cookie_category?.title[ i18n.resolvedLanguage ]??''
                    },
                    {
                      Header: <TransComponent i18nKey={ `general.action`}/>,
                      Cell: row => (
                        <>
                          <Button
                            id={ `UpdateContent_${ row.original.id }`}
                            className="mr-2 btn-icon btn-icon-only"
                            color="primary"
                            onClick={() => {
                              props.onChangeContentHOC( 'showUpdateMContentodal', true )
                              props.onChangeContentHOC( 'selectedContent', row.original )
                            }}
                          >
                            <i className="pe-7s-pen btn-icon-wrapper"> </i>
                          </Button>
                          <UncontrolledTooltip target={ `UpdateContent_${ row.original.id }`} placement="top">
                            <TransComponent i18nKey={ `cookie_content.tooltip.delete` }/>
                          </UncontrolledTooltip>
                          <Button
                            id={ `DeleteContent_${ row.original.id }` }
                            className="mr-2 btn-icon btn-icon-only"
                            color="danger"
                            onClick={() => {
                                props.onChangeContentHOC( 'showDeleteContentModal', true )
                                props.onChangeContentHOC( 'selectedContent', row.original )
                            }}
                          >
                            <i className="pe-7s-close btn-icon-wrapper"> </i>
                          </Button>
                          <UncontrolledTooltip target={ `DeleteContent_${ row.original.id }` } placement="top">
                            <TransComponent i18nKey={ `cookie_content.tooltip.delete` }/>
                          </UncontrolledTooltip>
                        </>
                      )
                    }
                  ]}
                showPagination={ true } />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ReactCSSTransitionGroup>
      <Modal
        isOpen={ props.showCreateContentModal }
        size={ 'xl' }
      >
        <ContentForm
          { ... props }
          mode={ 'create' }
          onClickSubmit={ props.createContent }
          onClose={ () => props.onChangeContentHOC( 'showCreateContentModal', false )}
        />
        { props.onLoadContent && <LoadingOverlay />}
      </Modal>
      <Modal
        isOpen={ props.showUpdateMContentodal }
        size={ 'xl' }
      >
        <ContentForm
          { ... props }
          mode={ 'edit' }
          selectedForm={ props.selectedContent }
          onClickSubmit={ val => props.updateContent( props.selectedContent.id, val )}
          onClose={ () => props.onChangeContentHOC( 'showUpdateMContentodal', false )}
        />
        { props.onLoadContent && <LoadingOverlay />}
      </Modal>
      <ConfirmationModal
        open={ props.showDeleteContentModal }
        titleKey={ `user.title.delete` }
        loading={ props.onLoadAdvisor }
        messageKey={ `user.desc.delete` }
        onClose={ () => props.onChangeContentHOC( 'showDeleteContentModal', false )}
        onClickConfirm={ () => props.deleteContent( props.selectedContent.id )}/>
      {( props.onLoadContent || 
        props.onLoadCategory
      ) && <LoadingOverlay />}
    </>
  )
}

export default compose(
  CategoryHOC,
  ContentHOC
)(Content)