import React, { useEffect } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { 
    Modal, 
    Row, Col, Button, UncontrolledTooltip,
    Card, CardBody
} from 'reactstrap'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import TransComponent from 'components/Trans'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import BKPTable from 'components/Table'
import CategoryForm from './form'
import ConfirmationModal from 'components/Modal/confirmation'
import { getItem } from 'utils/tokenStore'

import CategoryHOC from './actions/index'

const Category = props => {

  const { i18n } = useTranslation()

  useEffect(() => {
    props.getCategory()

    getItem( 'BPK_USER_TYPE' ) !== 'non-case' &&
    props.history.push( '/dashboard/case-mngmt' )
  }, [])
  
  return (
    <>
      <PageTitle
        heading={ <TransComponent i18nKey={ `cookie_category.title.main`}/>}
        subheading={ <TransComponent i18nKey={ `cookie_category.desc.main`}/> }
        icon="pe-7s-user icon-gradient bg-happy-itmeo"
        buttons={[
          {
            color: 'primary',
            onClick: () => {
              props.onChangeCategoryHOC( 'showCreateCategoryModal', true )
            },
            content: <TransComponent i18nKey={ `cookie_category.title.create`}/>
          }
        ]}
      />
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}>
        <Row>
          <Col md={12}>
            <Card className="main-card mb-3">
              <CardBody>
                <BKPTable
                  pageSize={ 10 }
                  data={ props.categories }
                  columns={[
                    {
                      Header: <TransComponent i18nKey={ 'general.title'}/>,
                      Cell: row => row.original.title[ i18n.resolvedLanguage ]
                    },
                    {
                      Header: <TransComponent i18nKey={ 'general.type'}/>,
                      Cell: row => (
                        <TransComponent i18nKey={ `cookie_category.fields.${ row.original.cookie_type.toLowerCase() }` }/>
                      )
                    },
                    {
                      Header: <TransComponent i18nKey={ 'cookie_category.fields.standard_condition'}/>,
                      Cell: row => (
                        <TransComponent i18nKey={ `cookie_category.fields.${ row.original.standard_condition.toLowerCase() }` }/>
                      )
                    },
                    {
                      Header: <TransComponent i18nKey={ 'general.position'}/>,
                      accessor: 'position'
                    },
                    {
                      Header: <TransComponent i18nKey={ 'general.status'}/>,
                      Cell: row => (
                        <span className={ `badge badge-${ row.original.status === 'ACTIVE' ? 'success' : 'danger' }`}>
                          <TransComponent i18nKey={ `general.${ row.original.status.toLowerCase() }` }/>
                        </span>
                      )
                    },
                    {
                      Header: <TransComponent i18nKey={ `general.action`}/>,
                      Cell: (row) => (
                        <>
                          <Button
                            id={ `UpdateCategory_${ row.original.id }`}
                            className="mr-2 btn-icon btn-icon-only"
                            color="primary"
                            onClick={() => {
                                props.onChangeCategoryHOC( 'showUpdateMCategoryodal', true )
                                props.onChangeCategoryHOC( 'selectedCategory', row.original )
                            }}
                          >
                            <i className="pe-7s-pen btn-icon-wrapper"> </i>
                          </Button>
                          <UncontrolledTooltip target={ `UpdateCategory_${ row.original.id }`} placement="top">
                            <TransComponent i18nKey={ `cookie_category.tooltip.edit`}/>
                          </UncontrolledTooltip>
                          <Button
                            id={ `DeleteCategory_${ row.original.id }` }
                            className="mr-2 btn-icon btn-icon-only"
                            color="danger"
                            onClick={() => {
                                props.onChangeCategoryHOC( 'showDeleteCategoryModal', true )
                                props.onChangeCategoryHOC( 'selectedCategory', row.original )
                            }}>
                            <i className="pe-7s-close btn-icon-wrapper"> </i>
                          </Button>
                          <UncontrolledTooltip target={ `DeleteCategory_${ row.original.id }` } placement="top">
                            <TransComponent i18nKey={ `cookie_category.tooltip.delete`}/>
                          </UncontrolledTooltip>
                        </>
                      )
                    }
                  ]}
                showPagination={ true } />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ReactCSSTransitionGroup>
      <Modal
        isOpen={ props.showCreateCategoryModal }
        size={ 'xl' }
      >
        <CategoryForm
          { ... props }
          mode={ 'create' }
          onClickSubmit={ props.createCategory }
          onClose={ () => props.onChangeCategoryHOC( 'showCreateCategoryModal', false )}
        />
        { props.onLoadCategory && <LoadingOverlay /> }
      </Modal>
      <Modal
        isOpen={ props.showUpdateMCategoryodal }
        size={ 'xl' }
      >
        <CategoryForm
          { ... props }
          mode={ 'edit' }
          selectedForm={ props.selectedCategory }
          onClickSubmit={ val => props.updateCategory( val.id, val )}
          onClose={ () => props.onChangeCategoryHOC( 'showUpdateMCategoryodal', false )}
        />
        { props.onLoadCategory && <LoadingOverlay /> }
      </Modal>
      <ConfirmationModal
        open={ props.showDeleteCategoryModal }
        titleKey={ `user.title.delete` }
        loading={ props.onLoadAdvisor }
        messageKey={ `user.desc.delete` }
        onClose={ () => props.onChangeCategoryHOC( 'showDeleteCategoryModal', false )}
        onClickConfirm={ () => props.deleteCategory( props.selectedCategory.id )}/>
      { props.onLoadCategory && <LoadingOverlay /> }
    </>
  )
}

export default compose(
  CategoryHOC
)(Category)