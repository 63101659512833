import React, { useState, useEffect } from 'react'
import { 
  Row, Col,
  Button,
  Card, CardHeader, CardBody,
  Form, FormGroup, Input, Label
} from 'reactstrap'
import _ from 'lodash'

import FormRenderer from './Renderer/FormRenderer'
import TermsContent from './Renderer/TermsContent'
import CaseSuccessContent from './Renderer/CaseSuccessContent'
import ReportingProcedure from './Renderer/ReportingProcedure'
import DataRemainAnonymous from './Renderer/DataRemainAnonymous'
import EditSectionContent from './EditSections'

import { DefaultJSONData } from '../assets'

import TransComponent from 'components/Trans'

function LangForm({
  mode,
  selectedForm,
  onClickSubmit
}) {
  const [ currentTab, setCurrentTab ] = useState( 'form_content' )
  const [ formData, onChangeFormData ] = useState( DefaultJSONData )
  const [ langVal, setLangVal ] = useState( '' )
  const [ langLabel, setLangLabel ] = useState( '' )

  const [ selectedEdit, setSelectedEdit ] = useState( null )

  useEffect(() => {
    if ( selectedForm !== null && mode !== 'create' ){
      onChangeFormData( selectedForm.form_json )
      setLangVal( selectedForm.language_key  )
      setLangLabel( selectedForm.language_value)
    }
  }, [ selectedForm ])

  const onChangeButtonForm = ( key, val, fieldKey ) => {
    let tempForm = _.cloneDeep( formData )
    if ( fieldKey === 'download_case_summary_button' ){
      tempForm[ 'case_success_content' ][ fieldKey ][ key ] = val
    }

    if ( fieldKey === 'submit_button' ){
      tempForm[ 'form_content' ][ fieldKey ][ key ] = val
    }

    if ( fieldKey === 'case_login_button' ){
      tempForm[ 'terms_content' ][ fieldKey ][ key ] = val
    }

    onChangeFormData( tempForm )
  }

  const onHandleChangeSelectedEdit = val => {
    Promise.all([
      setSelectedEdit( null )
    ]).then(() => setSelectedEdit( val ))
  }

  const onChangeFieldForm = ( key, val, fieldIndex, cardIndex, contentContext ) => {
    let tempContent = _.cloneDeep( formData[ currentTab ][ contentContext ] )

    if ( cardIndex ){
      tempContent[ cardIndex ][ contentContext ][ fieldIndex ][ key ] = val
    } else {
      tempContent[ fieldIndex ][ key ] = val
    }

    onChangeFormData({
      ... formData,
      [ currentTab ]: {
        ... formData[ currentTab ],
        [ contentContext ]: tempContent
      }
    })
  }

  return (
    <>
      <Card className='mb-4'>
        <CardHeader>
          <TransComponent i18nKey={ 'form.desc.add_lang' }/> 
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col md={ 6 }>
                <FormGroup>
                  <Label>
                    <TransComponent i18nKey={ `form.fields.lang_label` }/>
                  </Label>
                  <Input
                    disabled={ mode === 'view' }
                    value={ langLabel }
                    type={ 'text' }
                    onChange={ e => setLangLabel( e.target.value )}
                  />
                  <small> 
                    <TransComponent i18nKey={ 'general.example' }/>
                    { `: English` }
                  </small>
                </FormGroup>
              </Col>
              <Col md={ 6 }>
                <FormGroup>
                  <Label>
                    <TransComponent i18nKey={ `form.fields.lang_key` }/>
                  </Label>
                  <Input
                    disabled={ mode === 'view' }
                    value={ langVal }
                    type={ 'text' }
                    onChange={ e => setLangVal( e.target.value )}
                  />
                  <small> 
                    <TransComponent i18nKey={ 'general.example' }/>
                    { `: EN` }
                  </small>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
      <Row>
				<Col 
					xl={ 9 } lg={ 8 } 
					md={ 7 } sm={ 12 } 
					className={ 'bkp-form-renderer-col-7 bkp-form-renderer-col' }
				>		
          {
            currentTab === 'form_content' && (
              <FormRenderer
                mode={ mode }
                formData={ formData }
                selectedEdit={ selectedEdit }
        
                onChangeFormData={ onChangeFormData }
                onHandleChangeSelectedEdit={ onHandleChangeSelectedEdit }
                onChangeFieldForm={ onChangeFieldForm }
              />
            )
          }
          {
            currentTab === 'terms_content' && (
              <TermsContent
                mode={ mode }
                formData={ formData }
                selectedEdit={ selectedEdit }

                onHandleChangeSelectedEdit={ onHandleChangeSelectedEdit }
                onChangeFieldForm={ onChangeFieldForm }
              />
            )
          }
          {
            currentTab === 'reporting_procedure_modal' && (
              <ReportingProcedure
                mode={ mode }
                formData={ formData }
                selectedEdit={ selectedEdit }

                onHandleChangeSelectedEdit={ onHandleChangeSelectedEdit }
                onChangeFieldForm={ onChangeFieldForm }
              />
            )
          }
          {
            currentTab === 'data_remain_anonymous_modal' && (
              <DataRemainAnonymous
                mode={ mode }
                formData={ formData }
                selectedEdit={ selectedEdit }

                onHandleChangeSelectedEdit={ onHandleChangeSelectedEdit }
                onChangeFieldForm={ onChangeFieldForm }
              />
            )
          }
          {
            currentTab === 'case_success_content' && (
              <CaseSuccessContent
                mode={ mode }
                formData={ formData }
                selectedEdit={ selectedEdit }
                
                onHandleChangeSelectedEdit={ onHandleChangeSelectedEdit }
                onChangeFieldForm={ onChangeFieldForm }
              />
            )
          }
        </Col>
        <Col 
          xl={ 3 } lg={ 4 } md={ 5 } sm={ 12 } 
          className={ 'bkp-form-renderer-col' }
        >
          <EditSectionContent
            currentTab={ currentTab }
            formData={ formData }
            selectedEdit={ selectedEdit }
            
            setCurrentTab={ val => {
              setCurrentTab( val )
              setSelectedEdit( null )
            }}
            setSelectedEdit={ setSelectedEdit }
            onChangeFormData={ onChangeFormData }
            onChangeFieldForm={ onChangeFieldForm }
            onChangeButtonForm={ onChangeButtonForm }
          />
        </Col>
        {/*  handle editing section content */}
      </Row>
      {
        mode !== 'view' && (    
          <Button 
            color={ 'primary' } className={ 'mt-4' }
            disabled={ !langVal || !langLabel }
            onClick={ () => onClickSubmit({
              form_json: formData,
              language_value: langLabel ,
              language_key: langVal,
              ... mode === 'edit' && {
                id: selectedForm.id
              }
            })}
          >
            <TransComponent i18nKey={ `general.submit` }/>
          </Button>
        )
      }
    </>
  )
}

export default LangForm;