import React, { Component } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { 
  Row, Col, 
  Card, CardBody, CardHeader,
  Modal,
  Button, Label, FormGroup, Input,
  UncontrolledTooltip
} from 'reactstrap'

import { AiOutlineSearch, AiFillFile } from 'react-icons/ai'
import { GoAlert } from 'react-icons/go'
import { VscPinnedDirty } from 'react-icons/vsc'
import _ from 'lodash'

import { withTranslation } from 'react-i18next';
import PageTitle from 'components/Title/PageTitle'
import BKPTable from 'components/Table'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import DetailsModal from './Details'
import TransComponent from 'components/Trans'
import { ColumnsData, FilterOptions } from './assets'
import { getItem } from 'utils/tokenStore'

import TypeHOC from './actions/type'
import CasesHOC from './actions/index'
import CompanyHOC from './actions/company'

class Cases extends Component {

  componentDidMount = () => {
    Promise.all([
      this.props.getCompanies(),
      this.props.getTypes()
    ]).then(() => {
      let tempUserType = getItem( 'BPK_USER_TYPE' )
      tempUserType === 'case' 
        ? this.props.getCurrentCase()
        : this.props.getCases()
    })
  }

  onHandleFilterUpdate = val => {
    this.props.onChangeCaseHOC( 'filterMode', val )
    this.props.onChangeCaseHOC( 
      'currentViewCases',
      this.props.onAssignCurrentViewCases( val )
    )
    this.props.onChangeCaseHOC( 'searchKeyword', '' )
  }

  renderUrgentContent = () => (
    <Col lg={ 12 } className='mb-5'>
      <Card className="main-card">
        <CardHeader style={{ position: 'relative' }}>
          <GoAlert 
            id={ 'urgentCase' }
            style={{ 
              width: 50, height: 50, 
              padding: '0.6rem', borderRadius: '0.2rem',
              position: 'absolute', top: -15, right: 20
            }}
            className={ `text-white bg-danger` }
          />
          <UncontrolledTooltip target="urgentCase" placement="top">
            <TransComponent i18nKey="lawyer_case.tabs.urgent"/>
          </UncontrolledTooltip>
        </CardHeader>
        <CardBody>
          <BKPTable
            pageSize={ 10 }
            data={ this.props.urgentCases }
            columns={ ColumnsData({ 
              onClickDetails: this.onClickDetails,
              downloadCase: this.props.downloadCase
            })}
            showPagination={true} />
        </CardBody>
      </Card>
    </Col>
  )

  renderPinnedContent = () => (
    <Col lg={ 12 } className='mb-5'>
      <Card className="main-card">
        <CardHeader style={{ position: 'relative' }}>
          <VscPinnedDirty 
            id={ 'pinnedCase' }
            style={{ 
              width: 50, height: 50, 
              padding: '0.6rem', borderRadius: '0.2rem',
              position: 'absolute', top: -15, right: 20
            }}
            className={ `text-white bg-warning` }
          />
          <UncontrolledTooltip target="pinnedCase" placement="top">
            <TransComponent i18nKey="lawyer_case.tabs.pinned"/>
          </UncontrolledTooltip>
        </CardHeader>
        <CardBody>
          <BKPTable
            pageSize={ 10 }
            data={ this.props.pinnedCases }
            columns={ ColumnsData({ 
              onClickDetails: this.onClickDetails,
              downloadCase: this.props.downloadCase
            })}
            showPagination={true} />
        </CardBody>
      </Card>
    </Col>
  )

  onClickDetails = val => {
    // remove this lateron
    if ( val.status === 'NEW' ) {
      return this.props.updateCaseStatus( val.id, 'VIEWED' )
    }

    this.props.onChangeCaseHOC( 'showDetailsModal', true )
    this.props.onChangeCaseHOC( 'selectedCase', val )
  }

  renderAdminContent = () => {
    const { 
      currentViewCases, 
      searchKeyword,
      filterMode
    } = this.props
    return (
      <>
        <Row>
          { this.renderUrgentContent() }
          { this.renderPinnedContent() }
        </Row>
        <Card className="main-card mb-3">
          <CardHeader className="d-flex flex-wrap" style={{ position: 'relative' }}>
          {
            FilterOptions.map(( option, optionIndex ) => (
              <Button
                key={ optionIndex }
                className={ `mt-2 border-${ option.color } ${ optionIndex !== FilterOptions.length -1 ? 'mr-2' : '' } ${ filterMode === option.val ? `bg-${ option.color }` : `text-${ option.color } bg-white`  }`}
                onClick={ () => this.onHandleFilterUpdate( option.val )}
              >
                { option.label }
              </Button>
            ))
          }
          <AiFillFile 
            id={ 'otherCase' }
            style={{ 
              width: 50, height: 50, 
              padding: '0.6rem', borderRadius: '0.2rem',
              position: 'absolute', top: -15, right: 20
            }}
            className={ `text-white bg-secondary` }
          />
          <UncontrolledTooltip target="otherCase" placement="top">
            <TransComponent i18nKey="general.others"/>
          </UncontrolledTooltip>
          </CardHeader>
          <CardBody>
            <FormGroup>
              <Label>
                <AiOutlineSearch style={{ width: 15, height: 15 }} className='text-warning mr-2'/>
                <TransComponent i18nKey="lawyer_case.desc.search_case_id"/>
              </Label>
              <Input
                type="text"
                style={{ maxWidth: 250 }}
                value={ searchKeyword }
                onChange={ e => this.props.onChangeSearchValue( e.target.value )}
              />
            </FormGroup>
            <BKPTable
              pageSize={ 10 }
              data={ currentViewCases }
              columns={ ColumnsData({ 
                onClickDetails: this.onClickDetails,
                downloadCase: this.props.downloadCase
              })}
              showPagination={true} />
          </CardBody>
        </Card>
        <Modal size={ 'xl' } isOpen={ this.props.showDetailsModal }>
          <DetailsModal
            { ... this.props }

            resolvedLanguage={ this.props.i18n.resolvedLanguage }
            userType={ 'non-case' }
            onClose={ () => this.props.onChangeCaseHOC( 'showDetailsModal', false )}
          />
        </Modal>
      </>
    )
  }

  render = () => {
    let tempUserType = getItem( 'BPK_USER_TYPE' )

    return (
      <>
        { 
          tempUserType === 'non-case' && (
            <PageTitle
              subheading={ <TransComponent i18nKey="lawyer_case.desc.main"/> }
              heading={ <TransComponent i18nKey="lawyer_case.title.main"/> }
              icon="pe-7s-folder icon-gradient bg-happy-itmeo"
              buttons={[]}
            />
          )
        }
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          { 
            tempUserType !== 'case' && this.renderAdminContent()
          }
          {
            tempUserType === 'case' && this.props.selectedCase && (
              <Card>
                <DetailsModal
                  { ... this.props }
                  userType={ 'case' }
                />
              </Card>
            )
          }
        </ReactCSSTransitionGroup>
        {( 
          this.props.onLoadCase || 
          this.props.onLoadTypes ||
          this.props.onLoadCompany
        ) && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  TypeHOC,
  CompanyHOC,
  CasesHOC,
  withTranslation()
)(Cases)