import React from 'react'
import { Button } from 'reactstrap'
import CustomButton from './CustomButton'
import ReactHTMLParser from 'react-html-parser'

export default function RenderedButton({
  buttonKey,
  buttonProps,
  buttonStyle,
  selectedEdit,
  
  onClickEditButton
}) {
  return (
    <div 
      className={ `d-flex align-items-center flex-wrap transition_200 ${ selectedEdit === null ? 'bkp-form-renderer-title-hover' : '' }` }
      style={{
        marginBottom: 20, marginTop: 20,
        ... selectedEdit && 
        selectedEdit === buttonKey && {
          boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
          border: '1px solid #467bc6',
          borderStyle: 'dotted'
        }
      }}
    >
      <Button 
        onClick={ e => e.preventDefault()}
        style={{ 
          ... buttonStyle,
          color: buttonProps.style.color, 
          background: buttonProps.style.background,
          borderColor: buttonProps.style.background
        }}
      >
        {
          ReactHTMLParser( buttonProps.label ) 
        }
        <CustomButton
        type={ 'edit' }
        onClick={ onClickEditButton }
      />
      </Button>
    </div>
  )
}
