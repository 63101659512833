import React, { useCallback, useState, useEffect } from 'react'
import {
  Row, Col,
  Form, FormGroup, Label, Input, Button,
  ModalBody, ModalFooter, ModalHeader
} from 'reactstrap'
import _ from 'lodash'

import TransComponent from 'components/Trans'
import { useTranslation } from 'react-i18next'

import { 
  FieldData,
  EditingLanguages,
  DefaultFormValue
} from './assets'

const CookieContentForm = ({
  mode,
  categories,
  cookieContent,
  onClose,
  selectedForm,
  onClickSubmit
}) => {

  const [ contentForm, setContentForm ] = useState({
    ... DefaultFormValue,
    ms_center_id: 1
  })

  const { t, i18n } = useTranslation()

  const positionListInUse = useCallback(() => {
    let tempArray = {}

    cookieContent.map( category => {
      if ( tempArray[ category.cookie_category_id ] ){
        return tempArray[ category.cookie_category_id ].push({
          id: category.id,
          position: category[ 'position' ]
        })
      }   
      tempArray[ category.cookie_category_id ] = [
        {
          id: category.id,
          position: category[ 'position' ]
        }
      ]
    })

    if ( contentForm.cookie_category_id ){
      return _.findIndex( tempArray[ contentForm.cookie_category_id ],  positionObj => {
        return (
          positionObj.position === +contentForm.position &&
          ( !contentForm.id || positionObj.id !== contentForm.id  )
        )
      }) > -1
    } else {
      return false
    }
  }, [ cookieContent, contentForm ])

  const disabledSubmit = useCallback(() => {
    return _.values( contentForm ).some( val => {
      if ( typeof val !== 'string' ){
        return _.values( val ).some( childVal => !childVal )
      }
      return !val 
    }) || positionListInUse()
  }, [ contentForm, cookieContent ])
  

  useEffect(() => {
    if ( mode === 'edit' && selectedForm ){
      setContentForm( selectedForm )
    }
  }, [selectedForm])
  
  const updateCurrentContent = useCallback(( key, val ) => {
    let tmpCookieContent = _.cloneDeep( contentForm )

    if( key === 'title' || key === 'description' ){
      tmpCookieContent[ key ][ i18n.resolvedLanguage ] = val
    } else {
      tmpCookieContent[ key ] = val
    }

    setContentForm( tmpCookieContent )
  }, [ contentForm ])
  
  return(
    <>
      <ModalHeader toggle={() => onClose()}>
        <TransComponent i18nKey={ `cookie_content.title.${ mode }` }/>
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col md={ 12 }>
              <FormGroup>
                <Label style={{ width: "max-content", marginRight: "1rem" }} >
                  <TransComponent i18nKey={ 'general.current_editing_lang' }/>
                </Label>
                <div className='d-flex' style={{ gap: 10 }}>
                {
                  EditingLanguages.map( lang => (
                    <Button
                      color={ i18n.resolvedLanguage === lang ? 'primary' : 'secondary' }
                      onClick={ () => i18n.changeLanguage( lang ) }>{ lang }</Button>
                  ))
                }
                </div>
              </FormGroup>
            </Col>
            {
              FieldData({
                categories: categories,
                resolvedLanguage: i18n.resolvedLanguage
              }).map( field => (
                <Col md={ field.col || 12 }>
                  <FormGroup>
                    <Label>
                      <TransComponent i18nKey={ field.i18nKey }/>
                      { field.required && (
                        <span className='text-danger'>*</span>
                      )}
                    </Label>
                    {
                      field.type !== 'select' && (
                        <Input
                          type='text'
                          value={ 
                            field.multilang 
                              ? contentForm[ field.value ][ i18n.resolvedLanguage ] 
                              : contentForm[ field.value ]
                          }
                          onChange={ e => updateCurrentContent( field.value, e.target.value ) } />
                      )
                    }
                    {
                      field.type === 'select' && (
                        <Input
                          type='select'
                          value={ contentForm[ field.value ] }
                          onChange={ e => updateCurrentContent( 
                            field.value,  
                            e.target.value
                          )}>
                          <option value={ '' }></option>
                          {
                            field.options.map( option => (
                              <option value={ option.value }>{ option.label || t( option.i18nKey )}</option>
                            ))
                          }
                        </Input>
                      )
                    }
                    {
                      field.value === 'position' && 
                      positionListInUse() && (
                        <small className='text-danger'>
                          <TransComponent i18nKey={ 'cookie_category.desc.position_taken'}/>
                        </small>
                      )
                    }
                  </FormGroup>
                </Col>
              ))
            }
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary" 
          disabled={ disabledSubmit() }
          onClick={() => onClickSubmit({
            ... contentForm,
            cookie_category_id: +contentForm.cookie_category_id,
            position: +contentForm.position
          })}
        >
          <TransComponent i18nKey="general.submit"/>
        </Button>
      </ModalFooter>
    </>
  )
}

export default CookieContentForm