import React, { useState, useEffect } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  Button,
  CustomInput,
  Table,
  Label
} from 'reactstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'
import _ from 'lodash'

import TransComponent from 'components/Trans'

const CookieDetails = ({
  setCookieDetails,
  setAcceptedCookie,

  essentialCookies,
  activeCookieCategory,
  resolvedLanguage,
  acceptedCookies,
  setCookies
}) => {

  const [ selectedCookieContents, updateSelectedCookieContents ] = useState([])
  const [ selectedCookieCategory, updateSelectedCookieCategory ] = useState( activeCookieCategory?.[0]??{} )
  
  useEffect( () => {
    if( !_.isEmpty( selectedCookieCategory ) ){
      updateSelectedCookieContents( _.sortBy( selectedCookieCategory.contents, [ 'position' ], [ 'asc' ] ) )  
    }
  }, [ selectedCookieCategory ] )

  return(
    <>
      <ModalHeader toggle={ () => setCookieDetails( false )} >
        <TransComponent i18nKey={ 'cookie_content.desc.individual_setting' }/>
      </ModalHeader>
      <ModalBody className='p-4' >
        <PerfectScrollbar>
          {
            !_.isEmpty( selectedCookieCategory ) && (
              <Container>
                <Row>
                  <Col md={ 3 } style={{ maxHeight: '70vh', overflow: 'auto' }}>
                    {
                      activeCookieCategory?.[0] && 
                      activeCookieCategory.map( category => (
                        <Button 
                          className='mb-3 w-100'
                          color={ selectedCookieCategory.id === category.id ? 'primary' : 'secondary' }
                          onClick={ () => updateSelectedCookieCategory( category ) }>
                          { category.title[ resolvedLanguage ] }
                        </Button>
                      ))
                    }
                  </Col>
                  <Col md={ 9 } >
                    {
                      selectedCookieCategory.cookie_type !== 'STRICTLY_NECCESSARY' && 
                      selectedCookieCategory.standard_condition !== 'NO_DISPLAY' && (
                        <CustomInput
                          id={ 'category' + selectedCookieCategory.id }
                          className='mb-3'
                          type='switch'
                          style={{ background: "#3c3c3c" }}
                          label={ <TransComponent i18nKey={ 'general.accept_all' }/> }
                          checked={ 
                            selectedCookieCategory.contents.length > 0 &&
                            _.filter( selectedCookieCategory.contents, item => (
                              acceptedCookies.indexOf( item.id ) < 0 
                            )).length < 1
                          }
                          onChange={ e => {
                            let tmp = _.cloneDeep( acceptedCookies )
                            if( e.target.checked ){
                              selectedCookieCategory.contents.map( item => {
                                if( _.findIndex( tmp, acceptedCookie => acceptedCookie === item.id ) === -1 ){
                                  tmp.push( item.id )
                                }
                              })
                            } else {
                              tmp = _.filter( tmp, acceptedCookie => _.findIndex( selectedCookieCategory.contents, { id: acceptedCookie } ) < 0 )
                            }
                            setAcceptedCookie( tmp )
                          }} />
                      )
                    }
                    {
                      ( 
                        selectedCookieCategory.cookie_type === 'STRICTLY_NECCESSARY' ||
                        selectedCookieCategory.standard_condition === 'NO_DISPLAY' 
                      ) && (
                        <Label className='mb-3' >
                          <TransComponent i18nKey={ `cookie_category.fields.${ selectedCookieCategory.standard_condition.toLowerCase() }` }/>
                        </Label>
                      )
                    }
                    {
                      selectedCookieContents.map( content => (
                        <Table bordered className='mb-4'>
                          <tbody>
                            <style>
                            </style>
                            <tr>
                              <th scope="row" style={{ padding: "0.75rem" }}>
                                <TransComponent i18nKey={ 'general.accept' }/>
                              </th>
                              <td>
                                {
                                  selectedCookieCategory.cookie_type !== 'STRICTLY_NECCESSARY' && (
                                    <CustomInput
                                      id={ 'content' + content.id }
                                      type='switch'
                                      style={{ background: "#3c3c3c" }}
                                      label={ <TransComponent i18nKey={ 'general.accept' }/> }
                                      checked={ _.findIndex( acceptedCookies, item => item === content.id ) > -1 }
                                      onChange={ e => {
                                        let tmp = _.cloneDeep( acceptedCookies )
                                        if( e.target.checked ){
                                          tmp.push( content.id )
                                        } else {
                                          tmp = tmp.filter( x => x !== content.id )
                                        }
                                        setAcceptedCookie( tmp )
                                      }} />
                                  )
                                }
                                {
                                  selectedCookieCategory.cookie_type === 'STRICTLY_NECCESSARY' && (
                                    <Label>
                                      <TransComponent i18nKey={ `cookie_category.fields.${ selectedCookieCategory.standard_condition.toLowerCase() }` }/>
                                    </Label>
                                  )
                                }
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" style={{ padding: "0.75rem" }}>
                                <TransComponent i18nKey={ 'general.title' }/>
                              </th>
                              <td>{ content.title[ resolvedLanguage ] }</td>
                            </tr>
                            <tr>
                              <th scope="row" style={{ padding: "0.75rem" }}>
                                <TransComponent i18nKey={ 'cookie_content.fields.editor' }/>
                              </th>
                              <td>{ content.editor }</td>
                            </tr>
                            <tr>
                              <th scope="row" style={{ padding: "0.75rem" }}>
                                <TransComponent i18nKey={ 'general.description' }/>
                              </th>
                              <td>{ content.description[ resolvedLanguage ] }</td>
                            </tr>
                            <tr>
                              <th scope="row" style={{ padding: "0.75rem" }}>
                                <TransComponent i18nKey={ 'cookie_content.fields.url_published' }/>
                              </th>
                              <td><a>{ content.url_publisher }</a></td>
                            </tr>
                            <tr>
                              <th scope="row" style={{ padding: "0.75rem" }}>
                                <TransComponent i18nKey={ 'cookie_content.fields.cookie_name' }/>
                              </th>
                              <td>{ content.cookie_name }</td>
                            </tr>
                            <tr>
                              <th scope="row" style={{ padding: "0.75rem" }}>
                                <TransComponent i18nKey={ 'cookie_content.fields.running_time' }/>
                              </th>
                              <td>
                                { `${ content.running_time } `}
                                <TransComponent i18nKey={ 'general.days' }/>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      ))
                    }
                  </Col>
                </Row>
              </Container>
            )
          }
        </PerfectScrollbar>
      </ModalBody>
      <ModalFooter>
        <Button
          color='success'
          onClick={ () => {
            let tmpCookies = _.cloneDeep( acceptedCookies )
            essentialCookies.map( cookie => {
              if( _.findIndex( tmpCookies, acceptedCookie => acceptedCookie === cookie.id ) === -1 ){
                tmpCookies.push( cookie.id )
              }
            })
            setCookies( tmpCookies )
          }}>
          <TransComponent i18nKey={ 'general.save' }/>
        </Button>
        <Button
          color={ 'secondary' }
          onClick={ () => setCookieDetails( false ) }>
          <TransComponent i18nKey={ 'general.back' }/>
        </Button>
      </ModalFooter>
    </>
  )
}

export default CookieDetails