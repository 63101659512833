export const EditingLanguages = [ 'en', 'de']

export const CookieCondition = [
  {
    type: 'TARGETING',
    i18nKey: 'targeting',
    standardCondition: 'SWITCH_OFF'
  },
  {
    type: 'PRIVACY',
    i18nKey: 'privacy',
    standardCondition: 'SWITCH_OFF'
  },
  {
    type: 'STRICTLY_NECCESSARY',
    i18nKey: 'strictly_neccessary',
    standardCondition: 'ALWAYS'
  },
  {
    type: 'FUNCTIONALITY',
    i18nKey: 'functionality',
    standardCondition: 'SWITCH_OFF'
  },
  {
    type: 'TRACKING',
    i18nKey: 'tracking',
    standardCondition: 'SWITCH_OFF'
  },
  {
    type: 'INFORMATION',
    i18nKey: 'information',
    standardCondition: 'SWITCH_OFF'
  },
]

export const StandardConditions = [ 
  {
    i18nKey: 'switch_off',
    value: 'SWITCH_OFF'
  },
  {
    i18nKey: 'always',
    value: 'ALWAYS'
  },
  {
    i18nKey: 'no_display',
    value: 'NO_DISPLAY'
  },
  {
    i18nKey: 'switch_on',
    value: 'SWITCH_ON'
  }
]

export const DefaultFormValue = {
  title: {
    en: '',
    de: ''
  },
  description: {
    en: '',
    de: ''
  },
  position: 1,
  cookie_type: 'TARGETING',
  standard_condition: 'SWITCH_OFF',
  status: 'ACTIVE'
}