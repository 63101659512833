import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Get } from 'utils/axios'
import { requestError } from 'utils/requestHandler'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      categories: []
    }

    onChangeCategoryHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    
    getCategories = () => {
      Get(
        `/cookie_categories`,
        this.getCompanyCategorySuccess,
        this.getCompanyCategoryError,
        this.load
      )
    }
    getCompanyCategorySuccess = payload => this.setState({ categories: payload })
    getCompanyCategoryError = error => requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          categories={ this.state.categories }
          onLoadCategory={ this.state.loading }
          
          getCategories={ this.getCategories }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC