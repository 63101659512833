import React, { useEffect } from 'react' 
import _ from 'lodash'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { useTranslation } from 'react-i18next'

import TransComponent from 'components/Trans'
import LanguageSwitcher from 'components/LanguageSwitcher'
import BKPForm from './form'

import './index.scss'

const LandPage = ( props ) => {
  const { i18n } = useTranslation()

  useEffect( () => {
    BKPForm()
  }, [ i18n.resolvedLanguage ])

  return (
    <>
      <ReactCSSTransitionGroup>
        <div className={ 'bpk-landing-container'}>
          <div className="bpk-nav-bar">
            <div className='p-2 bg-white rounded'>
              <img src={ `https://whistleblower-center.proof-point.com/assets/BKP-Logo.png` } style={{ width: '120px' }}/>
            </div>
            <ul>
              <li>
                <LanguageSwitcher 
                  containerStyle={{ marginRight: -20 }}
                  arrowStyle={{ color: 'white' }}
                  buttonStyle={{ backgroundColor: `rgba(255,255,255,0.2)` }}
                />
              </li>
              {/* <li onClick={ () => props.history.push( '/admin/login' )}>
                <TransComponent i18nKey={ 'landing.admin-login' }/>
              </li> */}
              <li onClick={ () => props.history.push( '/case/login' )}>
                Login
              </li>
            </ul>
          </div>
          <header className={ 'bpk-landing-header' }>
            <h1>
              <TransComponent i18nKey={ 'landing.whistle-portal' }/>
            </h1>
            <h6>
              <TransComponent i18nKey={ 'landing.whistle-portal-desc' }/>
            </h6>
          </header>
          <div className={ 'bpk-landing-body' } style={{ paddingTop: '55px' }}>
            <div 
              id="bkp-plugin" 
              data-access-token="ZtO40z8tqELMHaRB6xJ1GSwptGzMgL4HTZTgy4qrZFfy6vD0rkVdoah4VBNc0AFR" 
              data-locale={ i18n.resolvedLanguage === 'en' ? 'en-EN' : 'de-DE' }>
            </div>
          </div>
          <footer className={ 'bpk-landing-footer row' }>
            <div className={ 'col-lg-4 col-md-6'}>
              <h5>
                <TransComponent i18nKey={ 'landing.information' }/>
              </h5>
              <a 
                href="https://www.whistleblowing-compliant.com/datenschutz" 
                target="_blank">
                <TransComponent i18nKey={ 'general.data-protection' }/>
              </a>
              <a 
                href="https://www.whistleblowing-compliant.com/impressum" 
                target="_blank">
                <TransComponent i18nKey={ 'general.imprint' }/>
              </a>
            </div>
            <div className={ 'col-lg-4 col-md-6'}>
              <h5>
                <TransComponent i18nKey={ 'landing.partner' }/>
              </h5>
              <h6>BKP-Cmpliant GmbH</h6>
              <h6>Heilig-Geist-Gasse 398</h6>
              <h6>84028 Landshut</h6>
              <h6>Tel.: 0871 935250</h6>
              <h6 className='mt-2'>Copyright @ BKP Compliant GmbH</h6>
            </div>
          </footer>
        </div>
      </ReactCSSTransitionGroup>
    </>
  )
}

export default LandPage;