import React, { useEffect, useState } from 'react'

import {
  Button, FormGroup, Label, Input,
  CardFooter, CardBody
} from 'reactstrap'

import TransComponent from 'components/Trans'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

export default function Company ({
  selectedUser,
  companies,
  onLoadCompanies,
  selectedCompanyUser,

  getCompanyUser,
  updateCompanyUser
}) {

  const [companyID, setCompanyID] = useState( null )

  useEffect(() => {
    getCompanyUser( selectedUser.id )
  }, [])

  useEffect(() => {
    selectedCompanyUser && setCompanyID( selectedCompanyUser.company_id )
  },[selectedCompanyUser])

  return (
    <>
      <CardBody>
        <FormGroup>
          <Label className={ 'd-flex' }>
            <TransComponent i18nKey={ `user.fields.company_id`}/>
            <span className="text-danger">*</span>
          </Label>
          <Input
            type={ 'select' }
            value={ companyID }
            onChange={ e => setCompanyID( e.target.value )}
          >
            <option value=""></option>
            { companies.map( data => (
              <option key={ data.id } value= { data.id }>{ data.name }</option>
            ))}
          </Input>
        </FormGroup>
      </CardBody>
      <CardFooter>
        <Button 
          color="primary"
          className="ml-auto"
          disabled={ !companyID }
          onClick={() => updateCompanyUser( selectedUser.id, +companyID )}>
          <TransComponent i18nKey={ `general.submit`}/>
        </Button>
      </CardFooter>
      { onLoadCompanies && <LoadingOverlay/> }
    </>
  )
}
