import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Get, Put } from 'utils/axios'
import { requestError, requestSuccess } from 'utils/requestHandler'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      forms: [],
      selectedForm: {},

      showUpdateModal: false
    }

    onChangeFormHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })

    getForms = () => Get(
      `/forms`,
      this.getFormsSuccess,
      this.getFormsError,
      this.load
    )
    getFormsSuccess = payload => this.setState({ forms: payload })
    getFormsError = error => requestError( error )

    getCompanyForm = companyId => Get(
      `/companies/${ companyId }/forms`,
      this.getCompanyFormSuccess,
      this.getCompanyFormError,
      this.load
    )
    getCompanyFormSuccess = payload => this.setState({ selectedForm: payload[0] })
    getCompanyFormError = error => requestError ( error )

    getSelectedForm = id => Get(
      `/forms/${ id }`,
      this.getSelectedFormSuccess,
      this.getSelectedFormError,
      this.load
    )
    getSelectedFormSuccess = payload => this.setState({ 
      selectedForm: payload,
      showUpdateModal: true
    })
    getSelectedFormError = error => requestError ( error )

    updateForm = dataToSubmit => Put(
      `/companies/${ dataToSubmit.company_id }/forms/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateFormSucces,
      this.updateFormError,
      this.load
    )
    updateFormSucces = payload => {
      this.getSelectedForm( payload.id )
      requestSuccess( 'Form has been updated successfully.' )
    }
    updateFormError = error => requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          forms={ this.state.forms }
          selectedForm={ this.state.selectedForm }
          onLoadForms={ this.state.loading }
          showUpdateModal={ this.state.showUpdateModal }

          getForms={ this.getForms }
          getCompanyForm={ this.getCompanyForm }
          getSelectedForm={ this.getSelectedForm }
          updateForm={ this.updateForm }
          onChangeFormHOC={ this.onChangeFormHOC }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC