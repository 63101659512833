import React, { Component } from 'react'
import { connect } from 'react-redux'
import { requestError } from 'utils/requestHandler'
import _ from 'lodash'
import { 
  Modal, ModalHeader
} from 'reactstrap'
import Cookies from 'js-cookie'
import TransComponent from 'components/Trans'

import { Get } from 'utils/axios'

import CookieConsent from 'components/Modal/CookieConsent'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={ 
      loading: false,
      cookies: [],
      activeCookieCategory: [],
      activeCookie: [],

      essentialCookieCategory: [],
      essentialCookies: [],

      showCookieConsentModal: false,
      acceptedCookies: [],
      isManualOpen: false
    }

    load = param => this.setState({ loading: param })

    componentDidUpdate = ( _, ps ) => {
      if ( ps.showCookieConsentModal !== this.state.showCookieConsentModal && 
          !this.state.showCookieConsentModal 
        ) {
        this.mountAcceptedCookies()
        this.setState({ isManualOpen: false })
      }
    }

    onChangeCookieHOC = ( key, val ) => this.setState({ [key]: val })

    mountAcceptedCookies = async () => {
      await this.getCookieCategories()
  
      let tmp = Cookies.get( 'agreed_cookie_ids' )
  
      if ( _.isEmpty( tmp )){
        return this.setState({ showCookieConsentModal: true })
      }
  
      let tmpCookies = JSON.parse( tmp )
  
      if( tmpCookies.length > 0 ){
  
        // to check previously saved cookies, is it still active and its category is it still active, return those valid ones only
        let tmpAcceptedCookies = _.filter( tmpCookies, cookie => {
          let tempFind = _.find( this.props.activeCookie, { id: cookie })
          return (
            !_.isEmpty( tempFind ) && 
            _.findIndex( this.props.activeCookieCategory, { id: tempFind.cookie_category_id }) > -1
          )
        })
  
        // to check validated cookie if there's missing essential cookie from current listing, if true then show the cookie banner
        _.filter( this.props.essentialCookies, cookie => _.findIndex( tmpAcceptedCookies, item => item === cookie.id ) === -1 ).length > 0 && 
        this.setState({ 
          showCookieConsentModal: true,
          acceptedCookies: tmpAcceptedCookies
        })
      } else {
        if( this.props.essentialCookies.length > 0 ){
        this.setState({ showCookieConsentModal: true })
        }
      }
    }

    getCookieCategories = () => Get(
      `/cookie_categories/contents`,
      this.getCookieCategoriesSuccess,
      this.getCookieCategoriesError,
      this.load
    )
    getCookieCategoriesSuccess = payload => {
      let tempEssential = _.filter( payload, { cookie_type: 'STRICTLY_NECCESSARY' })
      let tempActive = _.sortBy( payload, [ 'position' ])
        
      this.setState({ 
        activeCookieCategory: tempActive,
        activeCookie: _.flatten( _.map( tempActive, item => (
          item.contents
        ))),
        essentialCookies: _.flatten( _.map( tempEssential, item => (
          item.contents
        ))),
        essentialCookieCategory: tempEssential
      })
    }
    getCookieCategoriesError = error => requestError( error )

    render = () => {
      return(
        <>
          <WrappedComponent
            { ...this.props }
            onLoadCookies={ this.state.loading }

            activeCookieCategory={ this.state.activeCookieCategory }
            activeCookie={ this.state.activeCookie }

            essentialCookies={ this.state.essentialCookies }
            essentialCookieCategory={ this.state.essentialCookieCategory }

            getCookies={ this.getCookies }
            showCookieConsentModal={ this.state.showCookieConsentModal }
            acceptedCookies={ this.state.acceptedCookies }
            isManualOpen={ this.state.isManualOpen }
            onChangeCookieHOC={ this.onChangeCookieHOC }
            getCookieCategories={ this.getCookieCategories } 
            mountAcceptedCookies={ this.mountAcceptedCookies }
          />
          <Modal 
            isOpen={ this.state.showCookieConsentModal } 
            size='xl' >
            <ModalHeader 
              toggle={ 
                this.state.isManualOpen 
                  ? () => this.setState({ showCookieConsentModal: false })
                  : false 
              }
            >
              <TransComponent i18nKey={ 'cookie_content.fields.cookie_settings' }/>
            </ModalHeader>
            <CookieConsent
              onLoadCookies={ this.state.loading }
              activeCookie={ this.state.activeCookie }
              essentialCookies={ this.state.essentialCookies }
              activeCookieCategory={ this.state.activeCookieCategory }
              acceptedCookies={ this.state.acceptedCookies }
              setCookieConsentModal={ val => this.setState({ showCookieConsentModal: val })}
              setAcceptedCookie={ val => this.setState({ 
                acceptedCookies: val
              })}
            />  
          </Modal>
        </>
      )
    }
  }

  const mapStateToProps = props => ({ data: props })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC