import React from 'react'
import { Button, UncontrolledTooltip } from 'reactstrap'
import Moment from 'moment'
import i18n from 'i18next'
import { BsFillTelephoneFill } from 'react-icons/bs'
import { IoMdMailOpen } from 'react-icons/io'
import { FaUser, FaUserSecret } from 'react-icons/fa'
import { BsCloudDownloadFill } from 'react-icons/bs'

import TransComponent from 'components/Trans'

const handleStatusColor = id => {
  if ( id === 'NEW' ){
    return 'danger'
  }
  if ( id === 'VIEWED' ){
    return 'primary'
  }
  return 'success'
}

const handleStatusTooltip = id => {
  if ( id === 'NEW' ){
    return 'unseen'
  }
  if ( id === 'VIEWED' ){
    return 'progress'
  }
  return 'done'
}

export const ColumnsData = ({
  downloadCase,
  onClickDetails
}) => [
  {
    Header: <TransComponent i18nKey="lawyer_case.fields.case_id"/>,
    Cell: row => (
      <>
        <div 
          id={ `StatusSign${ row.original.id }` }
          className={ `rounded mr-2 bg-${ handleStatusColor( row.original.status )}` } 
          style={{ width: 10, height: 10 }}
        />
        <UncontrolledTooltip target={ `StatusSign${ row.original.id }` } placement="top">
          <TransComponent i18nKey={ `lawyer_case.tabs.${ handleStatusTooltip( row.original.status )}`}/>
        </UncontrolledTooltip>
        <span>{ row.original.uid }</span>
      </>
    )
  },
  {
    Header: <TransComponent i18nKey="lawyer_case.fields.user"/>,
    Cell: row => (
      <div className='d-block'>
        {
          row.original.is_anonymous && (
            <>
              <FaUserSecret style={{ height: 18, width: 18, marginRight: 7 }}/> 
              <TransComponent i18nKey="case.fields.anonymous"/>
            </>
          )
        }
        {
          !row.original.is_anonymous && (
            <>
              <div className="d-flex align-items-center flex-wrap mb-1">
                <FaUser style={{ marginRight: 7, width: 14, height: 14 }}/> <span>{ row.original.name }</span>
              </div>
              {/* <div className="d-flex align-items-center flex-wrap mb-1">
                <IoMdMailOpen style={{ marginRight: 7, width: 14, height: 14 }}/> <span>{ row.original.email }</span>
              </div>
              <div className="d-flex align-items-center flex-wrap">
                <BsFillTelephoneFill style={{ marginRight: 7, width: 14, height: 14 }}/> <span>{ row.original.contact }</span>
              </div> */}
            </>
          )
        }
      </div>
    )
  },
  {
    Header: <TransComponent i18nKey="lawyer_case.fields.department"/>,
    accessor: 'company_department'
  },
  {
    Header: <TransComponent i18nKey="lawyer_case.fields.company"/>,
    Cell: (row) => (
      <p className='mb-0'>
        { row.original.company_info?.name??'N/A' }
      </p>
    )
  },
  {
    Header: <TransComponent i18nKey="lawyer_case.tabs.case_closed"/>,
    Cell: (row) => (
      <>
        { 
          row.original.is_closed && (
            <h6 className="text-success">✓</h6>
        )}
        { 
          !row.original.is_closed && (
            <h6 className="text-danger">✕</h6>
        )}
      </>
    )
  },
  {
    Header: <TransComponent i18nKey="lawyer_case.fields.created_at"/>,
    Cell: (row) => (
      <p className='mb-0'>
        { Moment( row.original.created_at ).format( 'DD-MM-yyyy' ) }
      </p>
    )
  },
  {
    Header: <TransComponent i18nKey="general.action"/>,
    Cell: (row) => {
      return (
        <>
          <Button
            id={ `UpdateCase${ row.original.id }` }
            className="mr-2 btn-icon btn-icon-only"
            color="primary"
            onClick={() => onClickDetails( row.original )}
          >
            <i className="pe-7s-file btn-icon-wrapper"> </i>
          </Button>
          <UncontrolledTooltip target={ `UpdateCase${ row.original.id }` } placement="top">
            <TransComponent i18nKey="lawyer_case.tooltip.details"/>
          </UncontrolledTooltip>
          <Button
            id="DownloadCase"
            className="mr-2 btn-icon btn-icon-only"
            color="success"
            onClick={() => downloadCase( row.original, i18n.language )}
          >
            <BsCloudDownloadFill/>
          </Button>
          <UncontrolledTooltip target="DownloadCase" placement="top">
            <TransComponent i18nKey="lawyer_case.tooltip.download"/>
          </UncontrolledTooltip>
        </>
      )
    }
  }
]

export const FilterOptions = [
  {
    label: <TransComponent i18nKey="lawyer_case.tabs.all"/>,
    val: 'all',
    color: 'dark'
  },
  {
    label: <TransComponent i18nKey="lawyer_case.tabs.unseen"/>,
    val: 'unseen',
    color: 'danger'
  },
  {
    label: <TransComponent i18nKey="lawyer_case.tabs.progress"/>,
    val: 'progress',
    color: 'primary'
  },
  {
    label: <TransComponent i18nKey="lawyer_case.tabs.done"/>,
    val: 'done',
    color: 'success'
  },
  {
    label: <TransComponent i18nKey="lawyer_case.tabs.case_closed"/>,
    val: 'closed',
    color: 'danger'
  }
]