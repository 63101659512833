import React, { useState } from 'react'
import {
  Modal, ModalBody,
  Button, CustomInput
} from 'reactstrap'
import Cookies from 'js-cookie'
import _ from 'lodash'
import Loader from 'react-loaders'
import { useTranslation } from 'react-i18next'

import CookieDetails from './cookieDetails'
import TransComponent from 'components/Trans'

const CookieConsentContent = ({
  onLoadCookies,
  setAcceptedCookie,
  setCookieConsentModal,
  essentialCookies,
  acceptedCookies,

  activeCookie,
  activeCookieCategory
  }) => 
{

  const [ showCookieDetails, setCookieDetails ] = useState( false )

  const { i18n } = useTranslation()

  const setCookies = cookies => {
    let tmp = Cookies.get( 'agreed_cookie_ids' )

    if( tmp ){
      tmp = JSON.parse( tmp )
      // to check if current cookie is in sync as the latest unsaved accepted cookies
      if( JSON.stringify( _.sortBy( cookies, cookie => cookie ) ) !== JSON.stringify( _.sortBy( tmp, x => x ) ) ){
        Cookies.set( 'agreed_cookie_ids', JSON.stringify( cookies ) , '90' )
        window.location.reload()
      } else {
        setCookieConsentModal( false )
        setCookieDetails( false )
      }
    } else {
      Cookies.set( 'agreed_cookie_ids', JSON.stringify( cookies ) , '90' )
      window.location.reload()
    }
  }

  return(
    <>
      <ModalBody className='p-4' >
        <p>
          <TransComponent i18nKey={ 'cookie_content.desc.cookie_help' }/>
        </p>
        {
          onLoadCookies && (
            <Loader type="line-scale" active={ true }/>
          )
        }
        {
          !onLoadCookies && activeCookieCategory?.[0] && 
          activeCookieCategory.map(( category, categoryIndex ) => (
            <CustomInput
              key={ category.id }
              id={ `cookie-category-${ category.id }` }
              className='mr-3'
              type='checkbox'
              label={ category.title[ i18n.resolvedLanguage ] }
              checked={ 
                category.cookie_type === 'STRICTLY_NECCESSARY' 
                  ? true
                  : activeCookieCategory[ categoryIndex ].contents.length > 0 &&
                    _.filter( activeCookieCategory[ categoryIndex ].contents, item => (
                      acceptedCookies.indexOf( item.id ) < 0 
                    )).length < 1
              }
              disabled={ category.cookie_type === 'STRICTLY_NECCESSARY' }
              onChange={ e => {
                let tmp = _.cloneDeep( acceptedCookies )
                if( e.target.checked ){
                  activeCookieCategory[ categoryIndex ].contents.map( item => {
                    if( _.findIndex( tmp, acceptedCookie => acceptedCookie === item.id ) === -1 ){
                      tmp.push( item.id )
                    }
                  })
                } else {
                  tmp = _.filter( tmp, acceptedCookie => _.findIndex( activeCookieCategory[ categoryIndex ].contents, { id: acceptedCookie } ) < 0 )
                }
                
                setAcceptedCookie( tmp )
              }} 
            />
          ))
        }
        <div className='mb-3 d-flex flex-wrap mt-4 mb-3' style={{ gap: 20 }}>
          <Button
            color='primary'
            onClick={ () => {
              let tmpCookies = _.map( essentialCookies, cookie => cookie.id )
              
              setCookies( tmpCookies )
            }}>
            <TransComponent i18nKey={ 'cookie_content.fields.only_accept_essential' }/>
          </Button>
          <Button
            color='primary'
            onClick={ () => {
              let tmpCookies = _.cloneDeep( acceptedCookies )

              essentialCookies.map( cookie => {
                if( _.findIndex( tmpCookies, acceptedCookie => acceptedCookie === cookie.id ) === -1 ){
                  tmpCookies.push( cookie.id )
                }
              })
              setCookies( tmpCookies )
            }}>
            <TransComponent i18nKey={ 'general.save' }/>
          </Button>
          <Button
            color='primary'
            onClick={ () => {
              let tmpCookies = _.map( activeCookie, cookie => cookie.id )
              setCookies( tmpCookies )
            }}>
            <TransComponent i18nKey={ 'cookie_content.fields.accept_all_cookies' }/>
          </Button>
        </div>
        <div className='d-flex flex-wrap'>
          {
            activeCookieCategory.length > 0 &&(
              <a href={''} className={ 'mr-2' } onClick={ e => {
                e.preventDefault()
                setCookieDetails( true )
              }}>
                <TransComponent i18nKey={ 'cookie_content.desc.individual_setting' }/>
              </a>
            )
          } | 
          <a 
            href="https://www.whistleblowing-compliant.com/datenschutz" 
            target="_blank" className="ml-2 mr-2">
            <TransComponent i18nKey={ 'general.data-protection' }/>
          </a> | 
          <a 
            href="https://www.whistleblowing-compliant.com/impressum" 
            target="_blank" className={ 'ml-2' }>
            <TransComponent i18nKey={ 'general.imprint' }/>
          </a>
        </div>
      </ModalBody>
      {  
        <Modal isOpen={ showCookieDetails } size='xl' >
          <CookieDetails
            setCookieDetails={ setCookieDetails }
            setAcceptedCookie={ setAcceptedCookie }
            essentialCookies={ essentialCookies }
            activeCookieCategory={ activeCookieCategory }
            resolvedLanguage={ i18n.resolvedLanguage }
            acceptedCookies={ acceptedCookies }
            setCookies={ setCookies } 
          />
        </Modal>
      }
    </>
  )
} 

export default CookieConsentContent;