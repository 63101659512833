import React from 'react'
import _ from 'lodash'

export const DefaultFormValue = {
	title: {
		en: '',
		de: ''
	},
	description: {
		en: '',
		de: ''
	},
	cookie_name: '',
	cookie_script: '',
	editor: '',
	url_publisher: '',
	running_time: '',
	position: 1,
	status: 'ACTIVE',
	cookie_category_id: null
}

export const FieldData = ({ categories, resolvedLanguage }) => [
  {
    i18nKey: 'general.title',
    value: "title",
    multilang: true,
    required: true
  },
  {
    i18nKey: 'general.description',
    value: "description",
    multilang: true,
    required: true
  },
  {
    i18nKey: 'cookie_content.fields.cookie_category',
    value: 'cookie_category_id',
    required: true,
    type: 'select',
    col: 6,
    options: _.map( categories, item => ({
      value: +item.id,
      label: item.title[ resolvedLanguage ]
    }))
  },
  {
    i18nKey: 'cookie_content.fields.cookie_name',
    value: 'cookie_name',
    required: true,
    col: 6
  },
  {
    i18nKey: 'cookie_content.fields.cookie_code',
    value: 'cookie_script',
    required: true
  },
  {
    i18nKey: 'cookie_content.fields.editor',
    value: 'editor',
    required: true
  },
  {
    i18nKey: 'cookie_content.fields.url_published',
    value: 'url_publisher',
    required: false
  },
  {
    i18nKey: 'cookie_content.fields.running_time',
    value: 'running_time',
    required: true
  },
  {
    i18nKey: 'general.position',
    value: 'position',
    required: true,
    col: 6
  },
  {
    i18nKey: 'general.status',
    value: 'status',
    required: true,
    col: 6,
    type: 'select',
    options: [
      {
        value: 'ACTIVE',
        i18nKey: 'general.active'
      },
      {
        value: 'INACTIVE',
        i18nKey: 'general.inactive'
      }
    ]
  }
]

export const EditingLanguages = [ 'en', 'de']
