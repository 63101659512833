import TransComponent from 'components/Trans'

export const MainNav = [
  {
    icon: 'pe-7s-user',
    label: <TransComponent i18nKey="menu.roles"/>,
    to: "#/dashboard/admin/roles"
  },
  {
    icon: 'pe-7s-user',
    label: <TransComponent i18nKey="menu.users"/>,
    to: "#/dashboard/admin/users"
  },
  {
    icon: 'pe-7s-culture',
    label: <TransComponent i18nKey="menu.company"/>,
    to: "#/dashboard/admin/companies"
  },
  {
    icon: 'pe-7s-file',
    label: <TransComponent i18nKey="menu.case-type"/>,
    to: "#/dashboard/admin/case-type"
  },
  {
    icon: 'pe-7s-folder',
    label: <TransComponent i18nKey="menu.case-mngmt"/>,
    to: "#/dashboard/case-mngmt"
  },
  {
    icon: ' pe-7s-note2',
    label: <TransComponent i18nKey="menu.form-builder"/>,
    to: "#/dashboard/admin/form-builder"
  },
  {
    icon: ' pe-7s-global',
    label: <TransComponent i18nKey="menu.form-lang"/>,
    to: "#/dashboard/admin/form-lang"
  },
  // {
  //   icon: 'pe-7s-global',
  //   label: <TransComponent i18nKey="menu.language-translation"/>,
  //   to: "#/dashboard/admin/translation"
  // },
  {
    icon: 'pe-7s-box1',
    label: <TransComponent i18nKey="menu.cookie-banner"/>,
    secLabel: 'Cookie Banner',
    content: [
      {
        icon: 'pe-7s-notebook',
        label: <TransComponent i18nKey="menu.cookie-content"/>,
        to: '#/dashboard/admin/cookie-content'
      },
      {
        icon: 'pe-7s-bookmarks',
        label: <TransComponent i18nKey="menu.cookie-category"/>,
        to: '#/dashboard/admin/cookie-categories'
      }
    ]
  }
];