import React from 'react'
import TransComponent from 'components/Trans'

export const Footer = ({}) => {
  return (
    <div className="app-page-title d-flex justify-content-around" 
      style={{ marginTop: 70, marginBottom: 0, gap: 50, padding: '60px 30px' }}
    >
      <div style={{ textAlign: 'left' }}>
        <a 
          href="https://www.whistleblowing-compliant.com/datenschutz" 
          className='d-block mb-2' target="_blank"
          style={{ color: '#495057', opacity: 0.6, fontSize: '0.8rem', fontWeight: 500 }}
        >
          <TransComponent i18nKey={ 'general.data-protection' }/>
        </a>
      </div>
      <div style={{ textAlign: 'left' }}>
        <a 
          href="https://www.whistleblowing-compliant.com/impressum" 
          target="_blank"
          style={{ color: '#495057', opacity: 0.6, fontSize: '0.8rem', fontWeight: 500 }}
        >
          <TransComponent i18nKey={ 'general.imprint' }/>
        </a>
      </div>
    </div>
  )
}
export default Footer;