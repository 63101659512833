import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Get, Post, Put, Delete } from 'utils/axios'
import { requestError, requestSuccess } from 'utils/requestHandler'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      categories: [],
      selectedCategory: null,

      showCreateCategoryModal: false,
      showDeleteCategoryModal: false,
      showUpdateCategoryModal: false
    }

    onChangeCategoryHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })

    getCategory = () => {
      Get(
        `/cookie_categories`,
        this.getCategorySuccess,
        this.getCategoryError,
        this.load
      )
    }
    getCategorySuccess = payload => this.setState({ categories: payload })
    getCategoryError = error => requestError( error )

    getSelectedCategory = id => Get(
      `/cookie_categories/${ id }`,
      this.getSelectedCategorySuccess,
      this.getSelectedCategoryError,
      this.load
    )
    getSelectedCategorySuccess = payload => this.setState({ selectedCategory: payload })
    getSelectedCategoryError = error => requestError ( error )

    createCategory = dataToSubmit=> Post(
      `/cookie_categories`,
      dataToSubmit,
      this.createCategorySuccess,
      this.createCategoryError,
      this.load
    )
    createCategorySuccess = payload => {
      this.getCategory()
      this.setState({ showCreateCategoryModal: false })
      requestSuccess( 'New Category has been created successfully.' )
    }
    createCategoryError = error => requestError( error )

    deleteCategory = id => Delete( 
      `/cookie_categories/${ id }`,
      this.deleteCategorySuccess,
      this.deleteCategoryError,
      this.load
    )
    deleteCategorySuccess = () => {
      this.getCategory()
      this.setState({ showDeleteCategoryModal: false })
      requestSuccess( 'Category has been deleted succesfully.' )
    }
    deleteCategoryError = error => requestError( error )

    updateCategory = ( id, dataToSubmit ) => Put(
      `/cookie_categories/${ id }`,
      dataToSubmit,
      this.updateCategorySuccess,
      this.updateCategoryError,
      this.load
    )
    updateCategorySuccess = payload => {
      this.getCategory()
      this.getSelectedCategory( payload.id )
      requestSuccess( 'Category has been updated successfully.' )
    }
    updateCategoryError = error => requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          categories={ this.state.categories }
          onLoadCategory={ this.state.loading }
          selectedCategory={ this.state.selectedCategory }
          
          showCreateCategoryModal={ this.state.showCreateCategoryModal }
          showDeleteCategoryModal={ this.state.showDeleteCategoryModal }
          showUpdateMCategoryodal={ this.state.showUpdateMCategoryodal }

          getCategory={ this.getCategory }
          getSelectedCategory={ this.getSelectedCategory }
          createCategory={ this.createCategory }
          deleteCategory={ this.deleteCategory }
          updateCategory={ this.updateCategory }
          onChangeCategoryHOC={ this.onChangeCategoryHOC }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC