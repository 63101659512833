import React, { Fragment, useState } from 'react'
import _ from 'lodash'
import { BsGlobe2 } from 'react-icons/bs'
import { 
  DropdownToggle, DropdownMenu,
  Nav, NavItem,
  UncontrolledButtonDropdown 
} from 'reactstrap'
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'


const lngs = {
  en: { nativeName: 'English' },
  de: { nativeName: 'Deutsch' }
};

const SwitchRoles = ({
  containerStyle,
  buttonStyle,
  arrowStyle,
  
  buttonClass
}) => {
  const { i18n } = useTranslation();
  const [refresh, setrefresh] = useState( false )

  return (
    <UncontrolledButtonDropdown style={{ ... containerStyle }}>
      <DropdownToggle color="link" className={ 'd-flex align-items-center pl-0' }>
        <div 
          className={`icon-wrapper icon-wrapper-alt text-white ${ buttonClass }`} 
          style={{ 
            width: 'initial', height: 'initial', 
            padding: '8px 10px', overflow: 'initial', borderRadius: '1.2rem',
            ... buttonStyle
          }}
        >
          <BsGlobe2 style={{ fontSize: 18, marginRight: 10, color: 'white' }}/>
          <span>{ i18n.resolvedLanguage === 'en' ? 'English' : 'Deutsch' }</span>
        </div>
        <FontAwesomeIcon style={{ ... arrowStyle }} className="ml-2 opacity-8" icon={ faAngleDown } />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-xl rm-pointers">
          <div className="dropdown-menu-header mb-0">
              <div className="dropdown-menu-header-inner" style={{ padding: '0.8rem 0.5rem' }}>
                  <div className="menu-header-content text-dark">
                      <h5 className="menu-header-title">
                        { `Switch Site Language` }
                      </h5>
                  </div>
              </div>
          </div>
          <Nav vertical>
          {_.keys( lngs ).map((lng) => (
              <Fragment key={ lng }>
                <NavItem className="nav-item-divider text-align"/>
                <NavItem 
                  className={ `nav-item-btn text-center` }
                  style={{ cursor: 'pointer' }}
                  onClick={ () => {
                    i18n.changeLanguage(lng)
                    setrefresh( !refresh ) 
                  }}
                >
                  {lngs[lng].nativeName}
                  {
                    i18n.resolvedLanguage === lng && (
                      <div 
                      className="rounded-circle bg-primary ml-3" 
                      style={{ width: 10, height: 10, display: 'inline-block' }}
                    />
                    )
                  }
                </NavItem>
              </Fragment>
          ))}
        </Nav>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  )
}

const mapStateToProps = state => ({ data: state })
export default connect( mapStateToProps )( SwitchRoles );